import React from 'react'

const SvgPoint3 = props => (
  <svg viewBox='0 0 50 50' {...props}>
    <circle cx={25} cy={25} r={23} fill='none' stroke='#2b4b67' strokeWidth={2} />
    <path
      d='M18 30c-3.87 0-7-3.13-7-7s3.13-7 7-7c.1 0 .19 0 .29.01.86-2.9 3.54-5.01 6.71-5.01 3.87 0 7 3.13 7 7v.08c.33-.05.66-.08 1-.08 3.31 0 6 2.69 6 6s-2.69 6-6 6c-.34 0-.67-.03-1-.08V30H18z'
      fill='#fd5c1f'
    />
    <path d='M24.12 23.27L20.5 27 19 25.4l6-6.4 6 6.4-1.5 1.6-3.62-3.73v15.89h-1.77V23.27z' fill='#2b4b67' />
  </svg>
)

export default SvgPoint3
