import React from 'react'

const SvgFeature2 = props => (
  <svg id='feature2_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 60 60' xmlSpace='preserve' {...props}>
    <style>{'.feature2_svg__st1{fill-rule:evenodd;clip-rule:evenodd;fill:#fff}'}</style>
    <circle cx={30} cy={30} r={30} fill='#fd5c1f' />
    <path
      className='feature2_svg__st1'
      d='M30.87 27.44l-1.71-.01h-.01l-4.27 4.23-.6.59 1.21 1.22 1.82-1.8 1.83-1.82v1.83l-.08 16.31 1.72.01.07-16.32.01-1.82 1.82 1.83 1.8 1.82 1.22-1.21-.6-.6-4.23-4.26z'
    />
    <path
      className='feature2_svg__st1'
      d='M38.03 18.9c-1.39-4-5.18-6.88-9.66-6.9-5.68-.03-10.3 4.56-10.33 10.24 0 .38.02.76.06 1.13-3.49.9-6.08 4.06-6.1 7.83-.02 4.5 3.61 8.16 8.1 8.18l3.86.02v-1.72l-3.48-.02v-.02c-.13.01-.25.02-.38.02a6.438 6.438 0 01-6.4-6.46c.01-2.94 2.01-5.41 4.71-6.16a6.42 6.42 0 011.71-.24 8.535 8.535 0 01-.38-2.57c.02-4.73 3.88-8.55 8.61-8.53 3.56.02 6.6 2.2 7.88 5.29.22.53.38 1.08.49 1.66.33-.04.67-.06 1.02-.06.24 0 .47.02.71.04 4.4.38 7.84 4.07 7.82 8.57-.02 4.58-3.64 8.3-8.16 8.51l-2.23-.01v.03l-.01 1.54v.16l2.39.01v-.03c5.4-.29 9.7-4.73 9.72-10.21.04-5.57-4.4-10.13-9.95-10.3z'
    />
  </svg>
)

export default SvgFeature2
