import React from 'react'
import intl from 'react-intl-universal'
import { CommonBanner, InviteBanner } from '../../components/banners'
import { CommonTitle } from '../../components/titles'
import { LOCALES, Navigation, LocaleService } from '@raysync/common/services'
import { Swiper, Button, Wrapper, H4, Link } from '@raysync/common/components'
import DocumentCard from '../../components/document-cards'

import Layout from '../../components/layout'
import Table from '../../components/table'
import { ButtonContainer } from './product-sdk.atoms'
import {
  DemandSection,
  SSHFeatureSection,
  FeatureContainer,
  FeatureItem,
  TheorySection,
  ProcessSection,
  ProcessContainer,
  ProcessItem,
  ExampleSection,
  ExampleContainer,
  ExampleLeft,
  ExampleRight,
  ScenesSection,
  ScenesContainer,
  ScenesBox,
} from './product-vpn.atoms'

import Img1 from '../../images/products/ssh/image_1.png'
import Img2 from '../../images/products/ssh/image_2.png'
import Img3 from '../../images/products/ssh/image_3.png'

import Scenes1 from '../../images/products/ssh/scenes1.png'
import Scenes2 from '../../images/products/ssh/scenes2.png'

import Logo from '../../images/products/ssh/rayvision.png'

import Banner from '@raysync/common/components/svg/products/ssh/banner'
import Feature1 from '@raysync/common/components/svg/products/ssh/feature1'
import Feature2 from '@raysync/common/components/svg/products/ssh/feature2'
import Feature3 from '@raysync/common/components/svg/products/ssh/feature3'

import Point1 from '@raysync/common/components/svg/products/ssh/point1'
import Point2 from '@raysync/common/components/svg/products/ssh/point2'
import Point3 from '@raysync/common/components/svg/products/ssh/point3'

import TableZh from '@raysync/common/components/svg/products/ssh/table'
import TableEn from '@raysync/common/components/svg/products/ssh/table-en'

import Theory from '@raysync/common/components/svg/products/ssh/theory'
import TheoryEn from '@raysync/common/components/svg/products/ssh/theory-en'

const tablePic = {
  [LOCALES.ZH]: <TableZh />,
  [LOCALES.EN]: <TableEn />,
  [LOCALES.JA]: <TableEn />,
}[LocaleService.currentLocale]

const MainFuncItem = ({ onTrigger, children }) => {
  // const ref = useRef()
  // useHover(ref, onTrigger, true)
  return <ProcessItem>{children}</ProcessItem>
}

class ProductSSH extends React.PureComponent {
  state = {
    activedImg: Img1,
    featureList: [
      {
        title: intl.get('product5.feature.item1.title'),
        desc: intl.get('product5.feature.item1.desc'),
        svg: <Point1 />,
      },
      {
        title: intl.get('product5.feature.item2.title'),
        desc: intl.get('product5.feature.item2.desc'),
        svg: <Point2 />,
      },
      {
        title: intl.get('product5.feature.item3.title'),
        desc: intl.get('product5.feature.item3.desc'),
        svg: <Point3 />,
      },
    ],
    funcList: [
      {
        title: intl.get('product5.func.item1.title'),
        desc: intl.get('product5.func.item1.desc'),
        svg: <Feature1 />,
        img: Img1,
      },
      {
        title: intl.get('product5.func.item2.title'),
        desc: intl.get('product5.func.item2.desc'),
        svg: <Feature2 />,
        img: Img2,
      },
      {
        title: intl.get('product5.func.item3.title'),
        desc: intl.get('product5.func.item3.desc'),
        svg: <Feature3 />,
        img: Img3,
      },
    ],
  }
  // handleTrigger(index) {
  //   this.setState({ activedImg: this.state.funcList[index].img })
  // }
  render() {
    const { location } = this.props
    const { featureList, funcList, activedImg } = this.state
    const theoryPic = {
      [LOCALES.ZH]: <Theory />,
      [LOCALES.EN]: <TheoryEn />,
      [LOCALES.JA]: <TheoryEn />,
    }[intl.options.currentLocale]
    return (
      <Layout pageType='product5' location={location}>
        <Swiper>
          <CommonBanner type='product5' pic={<Banner />} />
        </Swiper>
        <DemandSection>
          <CommonTitle bottom={35}>{intl.get('product5.needs.title')}</CommonTitle>
          <p>{intl.get('product5.needs.desc1')}</p>
          <p>{intl.get('product5.needs.desc2')}</p>
        </DemandSection>
        <ProcessSection>
          <Wrapper>
            <CommonTitle bottom={54}>{intl.get('product5.func.title')}</CommonTitle>
            <ProcessContainer>
              <img src={activedImg} alt='feature' />
              <div>
                {funcList.map((l, i) => (
                  <MainFuncItem key={i}>
                    <div>{l.svg}</div>
                    <div>
                      <h4>{l.title}</h4>
                      <p>{l.desc}</p>
                    </div>
                  </MainFuncItem>
                ))}
              </div>
            </ProcessContainer>
          </Wrapper>
        </ProcessSection>
        <SSHFeatureSection>
          <Wrapper>
            <CommonTitle bottom={47}>{intl.get('product5.feature')}</CommonTitle>
            <FeatureContainer>
              {featureList.map((l, i) => (
                <FeatureItem key={i}>
                  <div>{l.svg}</div>
                  <h4>{l.title}</h4>
                  <p>{l.desc}</p>
                </FeatureItem>
              ))}
            </FeatureContainer>
            <ButtonContainer>
              <Button variant='outlined' onClick={() => Navigation.toApply({ type: 'prompt', pageType: 'product5' })}>
                {intl.get('product5.feature.button1')}
              </Button>
              <Button onClick={() => Navigation.toApply({ type: 'apply', pageType: 'product5' })}>
                {intl.get('product5.feature.button2')}
              </Button>
            </ButtonContainer>
          </Wrapper>
        </SSHFeatureSection>
        <TheorySection>
          <CommonTitle>{intl.get('product5.theory.title')}</CommonTitle>
          <p>{intl.get('product5.theory.desc1')}</p>
          <Table type='product5' />
          {theoryPic}
        </TheorySection>
        <ScenesSection>
          <CommonTitle bottom={45}>{intl.get('product5.scenes.title')}</CommonTitle>
          <ScenesContainer>
            <ScenesBox>
              <img src={Scenes1} alt='Scenes1' />
              <p>{intl.get('product5.scenes.item1.title')}</p>
            </ScenesBox>
            <ScenesBox>
              <img src={Scenes2} alt='Scenes2' />
              <p>{intl.get('product5.scenes.item2.title')}</p>
            </ScenesBox>
          </ScenesContainer>
        </ScenesSection>
        <ExampleSection>
          <Wrapper>
            <CommonTitle bottom={45}>{intl.get('product5.example')}</CommonTitle>
            <ExampleContainer>
              <ExampleLeft>
                <p>{intl.get('product5.example.desc1')}</p>
                <p>{intl.get('product5.example.desc2')}</p>
                {tablePic}
                <p>{intl.get('product5.example.desc3')}</p>
              </ExampleLeft>
              <ExampleRight>
                <img src={Logo} alt='Fox renderfarm Logo' />
                <H4>{intl.get('product5.example.desc4')}</H4>
                <p>{intl.get('product5.example.desc5')}</p>
                <p>{intl.get('product5.example.desc6')}</p>
              </ExampleRight>
            </ExampleContainer>
            <ButtonContainer>
              <Link to='/example'>
                <Button variant='outlined' style={{ margin: '0 auto' }}>
                  {intl.get('product5.example.button')}
                </Button>
              </Link>
            </ButtonContainer>
          </Wrapper>
        </ExampleSection>
        <Wrapper style={{ paddingTop: '68px', paddingBottom: '68px' }}>
          <CommonTitle>{intl.get('app.document.title')}</CommonTitle>
          <DocumentCard type='product5' />
        </Wrapper>
        <Swiper>
          <InviteBanner type='product5' />
        </Swiper>
      </Layout>
    )
  }
}

export default ProductSSH
