import React from 'react'

const SvgBanner = props => (
  <svg
    id='banner_svg__ed5440b3-3532-4be3-afee-0e417e329666'
    x={0}
    y={0}
    viewBox='0 0 480 360'
    xmlSpace='preserve'
    {...props}
  >
    <style>
      {
        '.banner_svg__st4{fill:#d9eaff}.banner_svg__st7{opacity:.5}.banner_svg__st8{fill:#6f8b99}.banner_svg__st124{fill:#fff}'
      }
    </style>
    <linearGradient
      id='banner_svg__SVGID_1_'
      gradientUnits='userSpaceOnUse'
      x1={193.224}
      y1={-958.952}
      x2={374.56}
      y2={-958.952}
      gradientTransform='matrix(1 0 0 -1 0 -624.889)'
    >
      <stop offset={0} stopColor='#00bfa5' />
      <stop offset={0.5} stopColor='#6dd9ca' />
      <stop offset={1} stopColor='#b5f2ea' />
    </linearGradient>
    <path
      d='M193.72 331.75l43.29 3.92c1.26 0 2.5.33 3.6.96l36.79 21.08c3.45 1.98 7.7 1.98 11.15 0l85.5-48.78'
      fill='none'
      stroke='url(#banner_svg__SVGID_1_)'
      strokeLinecap='round'
      strokeMiterlimit={10}
    />
    <linearGradient
      id='banner_svg__SVGID_2_'
      gradientUnits='userSpaceOnUse'
      x1={229.607}
      y1={-679.481}
      x2={334.504}
      y2={-679.481}
      gradientTransform='matrix(1 0 0 -1 0 -624.889)'
    >
      <stop offset={0} stopColor='#00bfa5' />
      <stop offset={0.5} stopColor='#6dd9ca' />
      <stop offset={1} stopColor='#b5f2ea' />
    </linearGradient>
    <path
      d='M334 72.81l-33.09-19.22c-1.66-.97-3.56-1.48-5.48-1.48h-15.44c-1.91 0-3.79-.5-5.45-1.46L251.9 37.59a9.087 9.087 0 00-9-.04l-12.8 7.2'
      fill='none'
      stroke='url(#banner_svg__SVGID_2_)'
      strokeLinecap='round'
      strokeMiterlimit={10}
    />
    <linearGradient
      id='banner_svg__SVGID_3_'
      gradientUnits='userSpaceOnUse'
      x1={250.927}
      y1={-820.481}
      x2={299.221}
      y2={-812.641}
      gradientTransform='matrix(1 0 0 -1 0 -624.889)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M272.11 189.42l-3.37-1.94a.426.426 0 00-.41 0l-4.45 2.57-6.09.46v3.15c.01.05.04.1.08.13l7.95 4.59c.13.07.29.07.41 0l10.46-6c.05-.02.08-.07.08-.13v-3.16l-4.66.33z'
      fill='url(#banner_svg__SVGID_3_)'
    />
    <linearGradient
      id='banner_svg__SVGID_4_'
      gradientUnits='userSpaceOnUse'
      x1={242.747}
      y1={-820.935}
      x2={281.339}
      y2={-811.08}
      gradientTransform='matrix(1 0 0 -1 0 -624.889)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M257.78 190.51c.01.05.04.1.08.13l7.95 4.59c.13.07.29.07.41 0l10.47-6.03c.05-.03.08-.08.08-.13 0-.06-.03-.11-.08-.13l-7.95-4.59a.426.426 0 00-.41 0l-10.47 6.03c-.05.02-.08.08-.08.13z'
      fill='url(#banner_svg__SVGID_4_)'
    />
    <path
      className='banner_svg__st4'
      d='M261.88 189.95c0 .03.02.06.04.07l4.5 2.57c.07.05.16.05.23 0l5.87-3.38c.03-.01.05-.04.05-.07-.01-.03-.03-.06-.05-.07l-4.46-2.57a.195.195 0 00-.23 0l-5.88 3.38c-.03.02-.04.05-.05.08l-.02-.01z'
    />
    <linearGradient
      id='banner_svg__SVGID_5_'
      gradientUnits='userSpaceOnUse'
      x1={267.294}
      y1={-761.329}
      x2={266.7}
      y2={-954.84}
      gradientTransform='matrix(1 0 0 -1 0 -624.889)'
    >
      <stop offset={0} stopColor='#007aff' stopOpacity={0} />
      <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M272.49 127.13l-4.46-2.57a.235.235 0 00-.23 0l-5.88 3.39a.09.09 0 00-.04.08c0 .34-.27 61.76 0 61.91 0 .03.02.06.04.07l4.5 2.57c.07.05.16.05.23 0l5.87-3.38c.03-.01.05-.04.05-.07v-61.91c0-.04-.03-.08-.08-.09z'
      fill='url(#banner_svg__SVGID_5_)'
    />
    <linearGradient
      id='banner_svg__SVGID_6_'
      gradientUnits='userSpaceOnUse'
      x1={267.454}
      y1={-759.628}
      x2={266.356}
      y2={-859.772}
      gradientTransform='matrix(1 0 0 -1 0 -624.889)'
    >
      <stop offset={0} stopColor='#007aff' stopOpacity={0} />
      <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M269.6 131.95l-2.06-1.22a.078.078 0 00-.11 0l-2.7 1.56v57.41l2.06 1.19c.03.02.07.02.11 0l2.7-1.57v-57.37z'
      opacity={0.5}
      fill='url(#banner_svg__SVGID_6_)'
    />
    <g className='banner_svg__st7'>
      <path
        className='banner_svg__st8'
        d='M64.44 283.02c-.28 0-.55-.07-.79-.2l-33.98-19.6a.798.798 0 01-.4-.63v-.07c.01-.28.16-.53.4-.67l25.78-14.89c.5-.29 1.11-.29 1.61 0l33.97 19.58c.17.09.3.25.36.44v.26c0 .28-.15.53-.4.67l-25.75 14.91c-.24.14-.51.21-.8.2zm-8.18-35.75c-.2 0-.39.05-.56.14L29.92 262.3c-.08.05-.13.13-.14.23-.01.11.04.21.14.26l33.97 19.58c.34.2.77.2 1.11 0l25.78-14.86c.07-.04.12-.12.14-.2a.25.25 0 00-.14-.25l-33.97-19.65a.963.963 0 00-.55-.14z'
      />
    </g>
    <g className='banner_svg__st7'>
      <path
        className='banner_svg__st8'
        d='M415.63 350.76c-.28 0-.56-.07-.8-.22l-33.97-19.61a.8.8 0 01-.4-.64v-.06c0-.28.15-.53.4-.67l25.78-14.85c.5-.29 1.11-.29 1.61 0l33.97 19.58c.17.1.3.25.36.44v.25c-.01.28-.16.53-.4.68l-25.78 14.89c-.23.13-.49.2-.77.21zM407.46 315c-.2 0-.39.05-.56.14l-25.74 14.89c-.08.05-.13.13-.14.23-.01.11.04.21.14.26l33.97 19.58c.34.2.77.2 1.11 0l25.78-14.89c.07-.04.12-.12.14-.2a.25.25 0 00-.14-.25l-33.97-19.58c-.17-.12-.38-.18-.59-.18z'
      />
    </g>
    <g className='banner_svg__st7'>
      <path
        className='banner_svg__st8'
        d='M113.88 285.43c-.18 0-.36-.05-.51-.14l-18.92-10.92a.56.56 0 01-.18-.78c.05-.07.11-.14.18-.18l14.42-8.31c.31-.18.7-.18 1.01 0l18.92 10.92c.18.1.28.29.28.5-.01.19-.11.37-.27.47l-14.42 8.31c-.16.08-.33.13-.51.13zm-19.2-11.51l18.92 10.93c.16.09.35.09.5 0l14.43-8.34-18.92-10.93a.497.497 0 00-.5 0l-14.42 8.31-.14.3.13-.27z'
      />
    </g>
    <g className='banner_svg__st7'>
      <path
        className='banner_svg__st8'
        d='M437.52 106.09c-.18 0-.35-.05-.5-.14L418.1 95.03a.594.594 0 01-.28-.45c-.01-.21.1-.41.28-.51l14.42-8.31c.31-.18.7-.18 1.01 0l18.92 10.92c.18.1.28.29.28.5-.01.19-.11.37-.28.47l-14.42 8.31c-.16.08-.34.13-.51.13zm-19.21-11.51l18.92 10.93c.16.09.35.09.5 0l14.42-8.34-18.9-10.97a.497.497 0 00-.5 0l-14.42 8.31-.13.3.11-.23z'
      />
    </g>
    <g className='banner_svg__st7'>
      <path
        className='banner_svg__st8'
        d='M20.31 135.48c-.18 0-.35-.04-.5-.13L.88 124.43a.553.553 0 01-.28-.45c-.01-.21.1-.41.28-.51l14.42-8.31c.32-.18.7-.18 1.02 0l18.92 10.93c.18.1.28.29.28.5-.01.19-.11.37-.28.47l-14.42 8.31c-.16.08-.33.12-.51.11zM1.1 123.99l18.92 10.92c.16.09.35.09.5 0l14.42-8.34v-.05L16.03 115.6a.497.497 0 00-.5 0l-14.42 8.3-.14.32.13-.23z'
      />
    </g>
    <g className='banner_svg__st7'>
      <path
        className='banner_svg__st8'
        d='M288.38 263.9c-.18 0-.35-.05-.5-.14l-18.92-10.92a.553.553 0 01-.28-.45c-.01-.21.1-.41.28-.51l14.42-8.31c.32-.18.7-.18 1.02 0l18.92 10.93c.18.1.29.29.28.5-.01.19-.11.37-.28.47l-14.42 8.3c-.17.08-.34.13-.52.13zm-19.21-11.5l18.92 10.92c.16.09.35.09.5 0l14.42-8.34v-.05L284.09 244a.497.497 0 00-.5 0l-14.42 8.31-.13.3.13-.21z'
      />
    </g>
    <g className='banner_svg__st7'>
      <path
        className='banner_svg__st8'
        d='M247.66 145.62c-.18 0-.35-.05-.5-.14l-18.92-10.93a.556.556 0 01-.21-.75.53.53 0 01.21-.21l14.42-8.31c.32-.18.7-.18 1.02 0l18.92 10.92c.18.1.28.29.28.5-.01.19-.11.37-.28.47l-14.42 8.31c-.16.1-.34.14-.52.14zm-19.2-11.51l18.92 10.93c.16.09.35.09.5 0l14.42-8.34c.01-.01.01-.03 0-.05l-18.92-10.92a.497.497 0 00-.5 0l-14.42 8.31-.13.3.13-.23z'
      />
    </g>
    <linearGradient
      id='banner_svg__SVGID_7_'
      gradientUnits='userSpaceOnUse'
      x1={507.643}
      y1={-748.619}
      x2={-208.553}
      y2={-1008.868}
      gradientTransform='matrix(1 0 0 -1 0 -624.889)'
    >
      <stop offset={0} stopColor='#00bfa5' />
      <stop offset={0.5} stopColor='#6dd9ca' />
      <stop offset={1} stopColor='#b5f2ea' />
    </linearGradient>
    <path
      d='M198.04 297.67l-.47-.9 9.76-5.1a6.45 6.45 0 003.46-5.72v-27.04c0-2.67 1.43-5.14 3.75-6.48l53.22-30.64c1.98-1.14 4.22-1.74 6.5-1.74h24.09c2.12 0 4.21-.56 6.05-1.63l145.93-85a2.87 2.87 0 001.02-3.92c-.26-.45-.64-.81-1.09-1.06l-49.23-26.68.5-.9 49.18 26.69a3.873 3.873 0 011.56 5.25c-.34.63-.85 1.15-1.46 1.51l-145.93 85a13.044 13.044 0 01-6.56 1.8h-24.08c-2.1 0-4.17.55-5.99 1.6l-53.22 30.64a6.513 6.513 0 00-3.23 5.6v27.04a7.433 7.433 0 01-4.01 6.62l-9.75 5.06z'
      fill='url(#banner_svg__SVGID_7_)'
    />
    <linearGradient
      id='banner_svg__SVGID_8_'
      gradientUnits='userSpaceOnUse'
      x1={493.894}
      y1={-707.499}
      x2={-222.302}
      y2={-967.748}
      gradientTransform='matrix(1 0 0 -1 0 -624.889)'
    >
      <stop offset={0} stopColor='#00bfa5' />
      <stop offset={0.5} stopColor='#6dd9ca' />
      <stop offset={1} stopColor='#b5f2ea' />
    </linearGradient>
    <path
      d='M195.01 296.25l-.56-.86 5.57-3.6a5.148 5.148 0 002.35-4.33v-21.03a5.18 5.18 0 00-2.52-4.43L61.07 179.52a6.206 6.206 0 01-3.02-5.32v-18.33a6.18 6.18 0 013.25-5.41l89.42-48.19.49.9-89.43 48.16c-1.66.9-2.69 2.62-2.7 4.51v18.33c0 1.82.96 3.5 2.52 4.43l138.78 82.47a6.228 6.228 0 013.02 5.32v21.02c.01 2.1-1.06 4.05-2.82 5.19l-5.57 3.65z'
      fill='url(#banner_svg__SVGID_8_)'
    />
    <linearGradient
      id='banner_svg__SVGID_9_'
      gradientUnits='userSpaceOnUse'
      x1={114.838}
      y1={-784.146}
      x2={114.838}
      y2={-784.146}
      gradientTransform='matrix(1 0 0 -1 0 -624.889)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <linearGradient
      id='banner_svg__SVGID_10_'
      gradientUnits='userSpaceOnUse'
      x1={303.687}
      y1={-941.981}
      x2={265.82}
      y2={-940.728}
      gradientTransform='matrix(1 0 0 -1 0 -624.889)'
    >
      <stop offset={0} stopColor='#fbd242' />
      <stop offset={1} stopColor='#f56329' />
    </linearGradient>
    <path
      d='M297 312.39l-11.99-6.94a.742.742 0 00-.68 0l-10.21 5.91c-.07.04-.11.11-.12.19v8.8c.01.1.08.19.17.23l11.99 6.94c.21.11.46.11.68 0l10.21-5.96c.06-.03.11-.08.12-.15v-8.84c-.02-.07-.09-.14-.17-.18z'
      fill='url(#banner_svg__SVGID_10_)'
    />
    <linearGradient
      id='banner_svg__SVGID_11_'
      gradientUnits='userSpaceOnUse'
      x1={297.434}
      y1={-938.513}
      x2={273.969}
      y2={-937.729}
      gradientTransform='matrix(1 0 0 -1 0 -624.889)'
    >
      <stop offset={0} stopColor='#e7f0ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M297.17 312.62v8.83a.291.291 0 00-.17-.23l-11.99-6.95a.742.742 0 00-.68 0l-10.21 5.91a.22.22 0 00-.12.16v-8.8c0-.08.05-.15.12-.19l10.21-5.91c.21-.11.46-.11.68 0l11.99 6.94c.1.05.16.13.17.24z'
      opacity={0.2}
      fill='url(#banner_svg__SVGID_11_)'
    />
    <linearGradient
      id='banner_svg__SVGID_12_'
      gradientUnits='userSpaceOnUse'
      x1={297.177}
      y1={-937.354}
      x2={274.007}
      y2={-936.588}
      gradientTransform='matrix(1 0 0 -1 0 -624.889)'
    >
      <stop offset={0} stopColor='#e7f0ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M297 312.39l-11.99-6.94a.742.742 0 00-.68 0l-10.21 5.91c-.07.04-.11.11-.12.19.02.09.09.17.17.21l11.99 6.95c.21.11.46.11.68 0l10.21-5.92c.07-.03.11-.1.12-.17a.291.291 0 00-.17-.23z'
      opacity={0.2}
      fill='url(#banner_svg__SVGID_12_)'
    />
    <g>
      <linearGradient
        id='banner_svg__SVGID_13_'
        gradientUnits='userSpaceOnUse'
        x1={486.228}
        y1={-787.54}
        x2={448.37}
        y2={-786.287}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M479.55 157.96l-11.99-6.95a.742.742 0 00-.68 0l-10.21 5.91c-.07.03-.12.1-.12.18v8.81c.01.1.08.19.17.23l11.98 6.95c.21.11.47.11.68 0l10.21-5.91a.22.22 0 00.12-.16v-8.83c0-.11-.07-.19-.16-.23z'
        fill='url(#banner_svg__SVGID_13_)'
      />
      <linearGradient
        id='banner_svg__SVGID_14_'
        gradientUnits='userSpaceOnUse'
        x1={479.981}
        y1={-784.077}
        x2={456.518}
        y2={-783.302}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M479.72 158.18V167a.333.333 0 00-.17-.23l-11.99-6.94a.742.742 0 00-.68 0l-10.21 5.92c-.07.03-.11.09-.12.16v-8.81c0-.08.04-.15.12-.18l10.21-5.91c.21-.11.46-.11.68 0l11.99 6.95c.09.05.15.13.17.22z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_14_)'
      />
      <linearGradient
        id='banner_svg__SVGID_15_'
        gradientUnits='userSpaceOnUse'
        x1={479.728}
        y1={-782.909}
        x2={456.556}
        y2={-782.143}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M479.55 157.96l-11.99-6.95a.742.742 0 00-.68 0l-10.21 5.91c-.07.03-.12.1-.12.18.02.1.08.18.17.22l11.98 6.94c.21.11.47.11.68 0l10.21-5.91c.07-.03.11-.1.12-.17a.265.265 0 00-.16-.22z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_15_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_16_'
        gradientUnits='userSpaceOnUse'
        x1={91.515}
        y1={-847.616}
        x2={67.444}
        y2={-846.823}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M87.27 219.74l-7.62-4.42a.466.466 0 00-.43 0l-6.49 3.76c-.04.02-.07.07-.07.12v5.6c.01.07.05.12.11.15l7.61 4.42c.14.07.3.07.43 0l6.5-3.76c.04-.02.07-.06.07-.11v-5.61a.21.21 0 00-.11-.15z'
        fill='url(#banner_svg__SVGID_16_)'
      />
      <linearGradient
        id='banner_svg__SVGID_17_'
        gradientUnits='userSpaceOnUse'
        x1={87.545}
        y1={-845.38}
        x2={72.627}
        y2={-844.884}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M87.38 219.85v5.61c-.01-.06-.05-.12-.11-.14l-7.62-4.42a.466.466 0 00-.43 0l-6.49 3.76c-.04.02-.07.06-.07.1v-5.6c0-.05.03-.09.07-.12l6.49-3.76c.14-.07.3-.07.43 0l7.62 4.42c.07.04.11.1.11.15z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_17_)'
      />
      <linearGradient
        id='banner_svg__SVGID_18_'
        gradientUnits='userSpaceOnUse'
        x1={87.383}
        y1={-844.673}
        x2={72.651}
        y2={-844.186}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M87.27 219.74l-7.62-4.42a.466.466 0 00-.43 0l-6.49 3.76c-.04.02-.07.07-.07.12.02.06.05.11.11.14l7.61 4.42c.14.07.3.07.43 0l6.5-3.76c.04-.02.07-.06.07-.11 0-.09-.04-.15-.11-.15z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_18_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_19_'
        gradientUnits='userSpaceOnUse'
        x1={87.279}
        y1={-737.583}
        x2={63.2}
        y2={-736.79}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M83.03 109.67l-7.61-4.37a.466.466 0 00-.43 0l-6.5 3.76c-.04.03-.07.07-.07.12v5.61c.01.06.05.12.11.14l7.62 4.42c.14.07.3.07.43 0l6.49-3.76c.04-.02.07-.06.07-.1v-5.68c-.02-.07-.06-.12-.11-.14z'
        fill='url(#banner_svg__SVGID_19_)'
      />
      <linearGradient
        id='banner_svg__SVGID_20_'
        gradientUnits='userSpaceOnUse'
        x1={83.298}
        y1={-735.326}
        x2={68.383}
        y2={-734.84}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M83.14 109.8v5.61c0-.05 0-.1-.11-.14l-7.61-4.42a.466.466 0 00-.43 0l-6.5 3.76c-.04.02-.06.06-.07.1v-5.6c0-.05.03-.09.07-.12l6.5-3.76c.14-.07.3-.07.43 0l7.61 4.42c.06.03.1.09.11.15z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_20_)'
      />
      <linearGradient
        id='banner_svg__SVGID_21_'
        gradientUnits='userSpaceOnUse'
        x1={83.139}
        y1={-734.64}
        x2={68.407}
        y2={-734.153}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M83.03 109.67l-7.61-4.37a.466.466 0 00-.43 0l-6.5 3.76c-.04.03-.07.07-.07.12.02.06.05.11.11.14l7.62 4.42c.14.07.3.07.43 0l6.49-3.76c.04-.02.07-.06.07-.11 0-.09-.04-.17-.11-.2z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_21_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_22_'
        gradientUnits='userSpaceOnUse'
        x1={305.662}
        y1={-805.346}
        x2={281.592}
        y2={-804.553}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M301.42 177.47l-7.61-4.42a.466.466 0 00-.43 0l-6.5 3.76c-.05.02-.07.07-.07.12v5.6c.01.07.05.12.11.15l7.62 4.42c.14.07.3.07.43 0l6.49-3.77c.04-.02.07-.06.07-.1v-5.61a.192.192 0 00-.11-.15z'
        fill='url(#banner_svg__SVGID_22_)'
      />
      <linearGradient
        id='banner_svg__SVGID_23_'
        gradientUnits='userSpaceOnUse'
        x1={301.688}
        y1={-803.147}
        x2={286.77}
        y2={-802.651}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M301.5 177.62v5.61a.193.193 0 00-.11-.14l-7.61-4.42a.466.466 0 00-.43 0l-6.5 3.77c-.04.02-.07.06-.07.1v-5.6c0-.05.03-.1.07-.12l6.5-3.76c.14-.07.3-.07.43 0l7.61 4.42c.06.03.1.08.11.14z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_23_)'
      />
      <linearGradient
        id='banner_svg__SVGID_24_'
        gradientUnits='userSpaceOnUse'
        x1={301.529}
        y1={-802.403}
        x2={286.797}
        y2={-801.917}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M301.42 177.47l-7.61-4.42a.466.466 0 00-.43 0l-6.5 3.76c-.05.02-.07.07-.07.12.02.06.06.1.11.14l7.62 4.42c.14.07.3.07.43 0l6.49-3.76c.04-.02.07-.06.07-.11a.192.192 0 00-.11-.15z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_24_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_25_'
        gradientUnits='userSpaceOnUse'
        x1={43.484}
        y1={-892.044}
        x2={91.85}
        y2={-884.194}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M64.69 260.98l-3.36-1.94a.426.426 0 00-.41 0l-4.45 2.57-6.11.46v3.15c0 .06.03.11.08.14l7.96 4.6c.13.07.29.07.41 0l10.48-6.05c.04-.03.07-.08.08-.13v-3.16l-4.68.36z'
        fill='url(#banner_svg__SVGID_25_)'
      />
      <linearGradient
        id='banner_svg__SVGID_26_'
        gradientUnits='userSpaceOnUse'
        x1={35.302}
        y1={-892.5}
        x2={73.936}
        y2={-882.632}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M50.35 262.07c0 .06.03.11.08.14l7.96 4.59c.13.07.29.07.41 0l10.48-6.04a.16.16 0 00.08-.14.16.16 0 00-.08-.14l-7.96-4.6a.426.426 0 00-.41 0l-10.48 6.08a.16.16 0 00-.08.14v-.03zm0 0'
        fill='url(#banner_svg__SVGID_26_)'
      />
      <path
        className='banner_svg__st4'
        d='M54.46 261.51c-.01.02-.01.05 0 .07l4.51 2.58c.07.05.16.05.23 0l5.88-3.39c.03-.01.04-.04.05-.07 0-.03-.02-.06-.05-.08l-4.46-2.58a.195.195 0 00-.23 0l-5.88 3.39c-.01.03-.01.06 0 .08h-.05z'
      />
      <linearGradient
        id='banner_svg__SVGID_27_'
        gradientUnits='userSpaceOnUse'
        x1={59.904}
        y1={-832.824}
        x2={59.309}
        y2={-1026.576}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M65.09 198.61l-4.46-2.58a.235.235 0 00-.23 0l-5.88 3.4c-.01.03-.01.06 0 .08 0 .34-.27 61.84 0 61.99-.01.02-.01.05 0 .07l4.51 2.58c.07.05.16.05.23 0l5.88-3.39c.03-.01.04-.04.05-.07v-62c-.02-.04-.06-.08-.1-.08z'
        fill='url(#banner_svg__SVGID_27_)'
      />
      <linearGradient
        id='banner_svg__SVGID_28_'
        gradientUnits='userSpaceOnUse'
        x1={60.048}
        y1={-831.121}
        x2={58.958}
        y2={-931.385}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M62.19 203.39l-2.05-1.19c-.03-.02-.07-.02-.11 0l-2.7 1.56v57.49l2.05 1.19c.04.02.08.02.12 0l2.7-1.57-.01-57.48z'
        opacity={0.5}
        fill='url(#banner_svg__SVGID_28_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_29_'
        gradientUnits='userSpaceOnUse'
        x1={295.465}
        y1={-914.781}
        x2={343.831}
        y2={-906.932}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M316.7 283.71l-3.36-1.95a.426.426 0 00-.41 0l-4.48 2.6-6.1.46v3.15c0 .05.03.1.08.13l7.96 4.6c.13.07.29.07.41 0l10.48-6.04a.16.16 0 00.08-.14v-3.16l-4.66.35z'
        fill='url(#banner_svg__SVGID_29_)'
      />
      <linearGradient
        id='banner_svg__SVGID_30_'
        gradientUnits='userSpaceOnUse'
        x1={287.28}
        y1={-915.221}
        x2={325.923}
        y2={-905.362}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M302.33 284.79c0 .06.03.11.08.14l7.96 4.59c.13.07.29.07.41 0l10.48-6.04a.16.16 0 00.08-.14.16.16 0 00-.08-.14l-7.96-4.59a.38.38 0 00-.41 0l-10.48 6.04c-.05.04-.08.09-.08.14z'
        fill='url(#banner_svg__SVGID_30_)'
      />
      <path
        className='banner_svg__st4'
        d='M306.44 284.24c0 .03.02.06.05.07l4.46 2.58c.07.05.17.05.24 0l5.88-3.39c.01-.02.01-.05 0-.07.01-.03.01-.06 0-.08l-4.51-2.58a.235.235 0 00-.23 0l-5.88 3.39c-.03.02-.05.05-.05.09l.04-.01z'
      />
      <linearGradient
        id='banner_svg__SVGID_31_'
        gradientUnits='userSpaceOnUse'
        x1={311.819}
        y1={-855.55}
        x2={311.225}
        y2={-1049.302}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M317.07 221.34l-4.51-2.58a.195.195 0 00-.23 0l-5.84 3.42c-.03.02-.05.05-.05.08 0 .34-.27 61.84 0 61.99 0 .03.02.06.05.07l4.46 2.58c.07.05.17.05.24 0l5.88-3.39c.01-.02.01-.05 0-.07v-62c.02-.02.02-.06 0-.1z'
        fill='url(#banner_svg__SVGID_31_)'
      />
      <linearGradient
        id='banner_svg__SVGID_32_'
        gradientUnits='userSpaceOnUse'
        x1={312.002}
        y1={-853.847}
        x2={310.903}
        y2={-954.111}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M314.17 226.16l-2.08-1.26c-.03-.02-.07-.02-.11 0l-2.7 1.56v57.49l2.06 1.19c.03.02.07.02.11 0l2.7-1.56.02-57.42z'
        opacity={0.5}
        fill='url(#banner_svg__SVGID_32_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_33_'
        gradientUnits='userSpaceOnUse'
        x1={24.211}
        y1={-794.532}
        x2={72.577}
        y2={-786.683}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M45.42 163.49l-3.36-1.95a.446.446 0 00-.42 0l-4.42 2.55-6.11.46v3.15c0 .05.03.1.08.13l7.95 4.6c.13.07.29.07.42 0l10.48-6.04c.04-.03.07-.08.08-.14v-3.16l-4.7.4z'
        fill='url(#banner_svg__SVGID_33_)'
      />
      <linearGradient
        id='banner_svg__SVGID_34_'
        gradientUnits='userSpaceOnUse'
        x1={16.027}
        y1={-794.976}
        x2={54.66}
        y2={-785.117}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M31.08 164.55c0 .06.03.11.08.14l7.95 4.59c.13.08.29.08.42 0l10.48-6.04a.16.16 0 00.08-.14.16.16 0 00-.08-.14l-7.96-4.59a.397.397 0 00-.42 0l-10.47 6.02c-.05.03-.08.09-.08.14v.02z'
        fill='url(#banner_svg__SVGID_34_)'
      />
      <path
        className='banner_svg__st4'
        d='M35.19 164c-.01.02-.01.05 0 .07l4.51 2.58c.07.05.16.05.23 0l5.88-3.39c.03-.01.04-.04.05-.07 0-.03-.02-.06-.05-.08l-4.51-2.58a.235.235 0 00-.23 0l-5.88 3.4c-.01.02-.01.06 0 .07z'
      />
      <linearGradient
        id='banner_svg__SVGID_35_'
        gradientUnits='userSpaceOnUse'
        x1={40.594}
        y1={-735.307}
        x2={39.999}
        y2={-929.058}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M45.81 101.1l-4.51-2.58a.195.195 0 00-.23 0l-5.88 3.39c-.01.03-.01.06 0 .08 0 .35-.27 61.84 0 61.99-.01.02-.01.05 0 .07l4.51 2.58c.07.05.16.05.23 0l5.88-3.39c.03-.01.04-.04.05-.07v-62c0-.03-.02-.06-.05-.07z'
        fill='url(#banner_svg__SVGID_35_)'
      />
      <linearGradient
        id='banner_svg__SVGID_36_'
        gradientUnits='userSpaceOnUse'
        x1={40.725}
        y1={-733.603}
        x2={39.635}
        y2={-833.868}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M42.92 105.91l-2.1-1.23c-.03-.02-.07-.02-.11 0l-2.7 1.57v57.49l2.05 1.19c.03.02.07.02.11 0l2.7-1.56.05-57.46z'
        opacity={0.5}
        fill='url(#banner_svg__SVGID_36_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_37_'
        gradientUnits='userSpaceOnUse'
        x1={251.588}
        y1={-885.853}
        x2={556.449}
        y2={-870.859}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M479.71 300.09c-.02.42-.26.81-.62 1.03l-60.45 34.91c-.98.58-2.2.58-3.19 0l-61.65-35.45c-.41-.23-.65-.67-.62-1.13v-93.76-.1c.03-.41.26-.79.62-.99l60.45-34.9c.99-.57 2.2-.57 3.19 0l61.66 35.45c.2.11.35.27.46.47v94.42l.15.05z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_37_)'
      />
      <linearGradient
        id='banner_svg__SVGID_38_'
        gradientUnits='userSpaceOnUse'
        x1={257.132}
        y1={-940.52}
        x2={553.253}
        y2={-925.958}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M479.7 282.81v17.28c-.02.43-.25.81-.62 1.03l-60.44 34.9c-.99.57-2.2.57-3.19 0l-61.66-35.45c-.4-.24-.64-.68-.62-1.14v-17.21c0 .45.23.86.62 1.09l61.66 35.45c.99.57 2.2.57 3.19 0l60.44-34.94c.38-.22.61-.61.63-1.04l-.01.03z'
        fill='url(#banner_svg__SVGID_38_)'
      />
      <linearGradient
        id='banner_svg__SVGID_39_'
        gradientUnits='userSpaceOnUse'
        x1={496.452}
        y1={-899.612}
        x2={-131.538}
        y2={-960.524}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M479.07 281.67l-61.65-35.45c-.99-.57-2.21-.57-3.2 0l-60.45 34.9c-.36.21-.59.58-.62.99v.1c0 .45.23.86.62 1.09l61.66 35.45c.99.57 2.2.57 3.19 0l60.45-34.93c.6-.35.81-1.12.47-1.72-.11-.19-.27-.36-.47-.47v.04z'
        fill='url(#banner_svg__SVGID_39_)'
      />
      <linearGradient
        id='banner_svg__SVGID_40_'
        gradientUnits='userSpaceOnUse'
        x1={477.385}
        y1={-916.785}
        x2={425.923}
        y2={-949.437}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M426.74 323.32c-.16 0-.31-.09-.39-.23a.464.464 0 01.16-.62l40.01-23.07c.22-.12.49-.05.62.16.12.22.04.49-.17.62l-40.01 23.07a.36.36 0 01-.22.07z'
        fill='url(#banner_svg__SVGID_40_)'
      />
      <linearGradient
        id='banner_svg__SVGID_41_'
        gradientUnits='userSpaceOnUse'
        x1={478.015}
        y1={-917.769}
        x2={426.552}
        y2={-950.422}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M470.19 298.27c-.17 0-.32-.1-.4-.24a.445.445 0 01.19-.6l.42-.23c.21-.13.49-.07.62.14s.07.49-.14.62c-.02.01-.04.02-.05.03l-.42.23c-.07.03-.14.05-.22.05z'
        fill='url(#banner_svg__SVGID_41_)'
      />
      <linearGradient
        id='banner_svg__SVGID_42_'
        gradientUnits='userSpaceOnUse'
        x1={478.109}
        y1={-917.915}
        x2={426.646}
        y2={-950.567}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M472.18 297.22a.455.455 0 01-.22-.86l.42-.22c.22-.12.49-.04.61.18s.04.49-.18.61h-.01l-.42.23c-.06.05-.13.06-.2.06z'
        fill='url(#banner_svg__SVGID_42_)'
      />
      <linearGradient
        id='banner_svg__SVGID_43_'
        gradientUnits='userSpaceOnUse'
        x1={478.171}
        y1={-918.022}
        x2={426.709}
        y2={-950.665}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M473.97 296.23a.455.455 0 01-.22-.86l.43-.23c.22-.11.48-.02.6.19.12.22.04.49-.18.61h-.01l-.42.23c-.05.05-.13.06-.2.06z'
        fill='url(#banner_svg__SVGID_43_)'
      />
      <linearGradient
        id='banner_svg__SVGID_44_'
        gradientUnits='userSpaceOnUse'
        x1={526.028}
        y1={-912.409}
        x2={410.952}
        y2={-914.992}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M474.06 266.47v15.74c-.02.38-.23.72-.56.9l-55.07 31.8c-.9.52-2 .52-2.9 0l-56.16-32.29c-.37-.21-.59-.61-.57-1.04v-15.64c0 .41.22.79.58.99l56.16 32.29c.9.51 2 .51 2.9 0l55.1-31.8c.34-.19.56-.56.57-.95h-.05z'
        fill='url(#banner_svg__SVGID_44_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_45_'
          gradientUnits='userSpaceOnUse'
          x1={257.132}
          y1={-914.841}
          x2={553.253}
          y2={-900.27}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M479.7 257.13v17.28c-.02.42-.25.81-.62 1.02l-60.44 34.91c-.99.57-2.2.57-3.19 0l-61.66-35.45c-.4-.23-.64-.68-.62-1.14v-17.2c0 .45.23.86.62 1.09l61.66 35.44c.98.58 2.2.58 3.19 0l60.45-34.9c.38-.22.62-.61.63-1.04h-.02z'
          fill='url(#banner_svg__SVGID_45_)'
        />
        <linearGradient
          id='banner_svg__SVGID_46_'
          gradientUnits='userSpaceOnUse'
          x1={496.454}
          y1={-873.938}
          x2={-131.537}
          y2={-934.86}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={1} stopColor='#003cff' />
        </linearGradient>
        <path
          d='M479.07 255.99l-61.65-35.45c-.99-.57-2.21-.57-3.2 0l-60.45 34.9c-.36.21-.59.58-.62 1v.1c0 .45.23.86.62 1.09l61.66 35.44c.98.58 2.2.58 3.19 0l60.45-34.9c.6-.34.81-1.1.47-1.7-.1-.2-.27-.36-.47-.48z'
          fill='url(#banner_svg__SVGID_46_)'
        />
        <linearGradient
          id='banner_svg__SVGID_47_'
          gradientUnits='userSpaceOnUse'
          x1={477.387}
          y1={-891.11}
          x2={425.924}
          y2={-923.753}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M426.74 297.64c-.25 0-.45-.21-.45-.45 0-.16.09-.3.22-.38l40.01-23.07a.45.45 0 11.53.73c-.02.02-.05.03-.08.05l-40.01 23.07a.51.51 0 01-.22.05z'
          fill='url(#banner_svg__SVGID_47_)'
        />
        <linearGradient
          id='banner_svg__SVGID_48_'
          gradientUnits='userSpaceOnUse'
          x1={477.985}
          y1={-892.045}
          x2={426.522}
          y2={-924.697}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M470.19 272.59c-.25.06-.5-.1-.55-.35-.06-.25.1-.5.35-.55l.42-.23a.46.46 0 01.61.19c.11.22.03.49-.19.61l-.42.22c-.07.06-.14.1-.22.11z'
          fill='url(#banner_svg__SVGID_48_)'
        />
        <linearGradient
          id='banner_svg__SVGID_49_'
          gradientUnits='userSpaceOnUse'
          x1={478.11}
          y1={-892.239}
          x2={426.647}
          y2={-924.882}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M472.18 271.54c-.25 0-.45-.2-.45-.45 0-.17.09-.32.24-.4l.42-.22c.22-.12.5-.03.61.19s.03.5-.19.61l-.42.22c-.07.03-.14.05-.21.05z'
          fill='url(#banner_svg__SVGID_49_)'
        />
        <linearGradient
          id='banner_svg__SVGID_50_'
          gradientUnits='userSpaceOnUse'
          x1={478.172}
          y1={-892.337}
          x2={426.71}
          y2={-924.989}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M473.97 270.55a.432.432 0 01-.43-.23.46.46 0 01.19-.61l.43-.23a.46.46 0 01.61.19c.11.22.03.49-.19.61l-.42.22c-.06.03-.13.05-.19.05z'
          fill='url(#banner_svg__SVGID_50_)'
        />
      </g>
      <linearGradient
        id='banner_svg__SVGID_51_'
        gradientUnits='userSpaceOnUse'
        x1={526.027}
        y1={-886.708}
        x2={410.952}
        y2={-889.3}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M474.06 240.79v15.74c-.02.38-.23.72-.56.9l-55.08 31.79c-.9.51-2 .51-2.9 0l-56.16-32.29c-.37-.21-.59-.61-.57-1.04v-15.64c0 .41.22.79.58 1l56.16 32.28c.9.52 2 .52 2.9 0l55.11-31.79c.34-.2.56-.56.57-.95h-.05z'
        fill='url(#banner_svg__SVGID_51_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_52_'
          gradientUnits='userSpaceOnUse'
          x1={257.132}
          y1={-889.454}
          x2={553.253}
          y2={-874.892}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M479.7 231.75v17.28c-.01.43-.25.82-.62 1.03l-60.44 34.89c-.99.57-2.2.57-3.19 0l-61.66-35.45c-.4-.23-.64-.68-.62-1.14v-17.19c0 .45.23.86.62 1.08l61.66 35.45c.98.58 2.2.58 3.19 0l60.44-34.9c.37-.22.61-.62.62-1.05z'
          fill='url(#banner_svg__SVGID_52_)'
        />
        <linearGradient
          id='banner_svg__SVGID_53_'
          gradientUnits='userSpaceOnUse'
          x1={496.454}
          y1={-848.558}
          x2={-131.537}
          y2={-909.479}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={1} stopColor='#003cff' />
        </linearGradient>
        <path
          d='M479.07 230.61l-61.65-35.45c-.99-.57-2.21-.57-3.2 0l-60.45 34.9c-.36.21-.59.58-.62.99v.11c0 .45.23.86.62 1.08l61.66 35.45c.98.58 2.2.58 3.19 0l60.45-34.9c.38-.22.61-.61.63-1.04.03-.47-.22-.91-.63-1.14z'
          fill='url(#banner_svg__SVGID_53_)'
        />
        <linearGradient
          id='banner_svg__SVGID_54_'
          gradientUnits='userSpaceOnUse'
          x1={477.387}
          y1={-865.721}
          x2={425.925}
          y2={-898.374}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M426.74 272.26c-.25 0-.45-.21-.45-.45 0-.16.09-.3.22-.38l40.01-23.08c.22-.12.49-.04.62.17.12.22.04.49-.17.61l-40.01 23.07c-.06.04-.14.06-.22.06z'
          fill='url(#banner_svg__SVGID_54_)'
        />
        <linearGradient
          id='banner_svg__SVGID_55_'
          gradientUnits='userSpaceOnUse'
          x1={478.012}
          y1={-866.708}
          x2={426.55}
          y2={-899.352}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M470.19 247.2a.47.47 0 01-.4-.23.46.46 0 01.19-.61l.42-.23c.22-.12.5-.03.61.19s.03.5-.19.61l-.42.23c-.06.03-.13.04-.21.04z'
          fill='url(#banner_svg__SVGID_55_)'
        />
        <linearGradient
          id='banner_svg__SVGID_56_'
          gradientUnits='userSpaceOnUse'
          x1={478.111}
          y1={-866.852}
          x2={426.649}
          y2={-899.505}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M472.18 246.16c-.17 0-.33-.09-.41-.24a.445.445 0 01.19-.6l.42-.23c.22-.12.49-.04.61.18v.01c.12.21.04.48-.18.6 0 0-.01 0-.01.01l-.42.23c-.06.02-.13.04-.2.04z'
          fill='url(#banner_svg__SVGID_56_)'
        />
        <linearGradient
          id='banner_svg__SVGID_57_'
          gradientUnits='userSpaceOnUse'
          x1={478.173}
          y1={-866.959}
          x2={426.711}
          y2={-899.611}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M473.97 245.17c-.17 0-.33-.09-.41-.24a.457.457 0 01.19-.6l.43-.23a.447.447 0 01.41.79l-.42.23c-.05.03-.13.05-.2.05z'
          fill='url(#banner_svg__SVGID_57_)'
        />
      </g>
      <linearGradient
        id='banner_svg__SVGID_58_'
        gradientUnits='userSpaceOnUse'
        x1={526.027}
        y1={-861.336}
        x2={410.952}
        y2={-863.919}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M474.06 215.42v15.72c-.02.38-.23.72-.56.9l-55.07 31.79c-.9.52-2 .52-2.9 0l-56.17-32.21c-.37-.21-.59-.62-.57-1.04v-15.71c0 .41.22.79.58.99l56.16 32.29c.9.52 2 .52 2.9 0l55.11-31.83c.34-.19.56-.55.57-.95l-.05.05z'
        fill='url(#banner_svg__SVGID_58_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_59_'
          gradientUnits='userSpaceOnUse'
          x1={257.132}
          y1={-863.97}
          x2={553.253}
          y2={-849.408}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M479.7 206.26v17.26c-.02.43-.25.81-.62 1.03l-60.44 34.92c-.99.57-2.2.57-3.19 0l-61.66-35.46c-.4-.23-.64-.67-.62-1.13v-17.2c0 .45.23.86.62 1.09l61.66 35.45c.99.57 2.2.57 3.19 0l60.44-34.89c.37-.23.61-.62.62-1.07z'
          fill='url(#banner_svg__SVGID_59_)'
        />
        <linearGradient
          id='banner_svg__SVGID_60_'
          gradientUnits='userSpaceOnUse'
          x1={221.942}
          y1={-838.602}
          x2={496.733}
          y2={-827.631}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M479.7 206.21v.05a1.194 1.194 0 01-.62 1.05l-20.22 11.72-22.03 12.68-18.19 10.51c-.99.57-2.2.57-3.19 0l-19.4-11.16-22.04-12.66-20.22-11.63c-.39-.22-.63-.64-.63-1.09v-.1c.03-.41.26-.79.62-.99l60.45-34.9c.99-.57 2.2-.57 3.19 0l61.66 35.45c.2.11.35.27.46.47.1.18.16.38.16.6z'
          fill='url(#banner_svg__SVGID_60_)'
        />
        <linearGradient
          id='banner_svg__SVGID_61_'
          gradientUnits='userSpaceOnUse'
          x1={477.385}
          y1={-840.239}
          x2={425.923}
          y2={-872.891}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M426.74 246.78c-.16 0-.31-.09-.39-.23a.464.464 0 01.16-.62l40.01-23.07c.22-.12.49-.05.62.16.12.22.04.49-.17.62l-40.01 23.07a.44.44 0 01-.22.07z'
          fill='url(#banner_svg__SVGID_61_)'
        />
        <linearGradient
          id='banner_svg__SVGID_62_'
          gradientUnits='userSpaceOnUse'
          x1={478.015}
          y1={-841.224}
          x2={426.552}
          y2={-873.876}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M470.19 221.72c-.17 0-.32-.1-.4-.24a.445.445 0 01.19-.6l.42-.23c.21-.13.49-.07.62.14.13.21.07.49-.14.62-.02.01-.04.02-.05.03l-.42.23c-.07.03-.14.05-.22.05z'
          fill='url(#banner_svg__SVGID_62_)'
        />
        <linearGradient
          id='banner_svg__SVGID_63_'
          gradientUnits='userSpaceOnUse'
          x1={478.082}
          y1={-841.328}
          x2={426.62}
          y2={-873.98}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M472.18 220.68c-.25.06-.5-.09-.56-.34-.06-.25.09-.5.34-.56l.42-.22a.46.46 0 01.61.19c.12.22.04.49-.18.61h-.01l-.42.23a.41.41 0 01-.2.09z'
          fill='url(#banner_svg__SVGID_63_)'
        />
        <linearGradient
          id='banner_svg__SVGID_64_'
          gradientUnits='userSpaceOnUse'
          x1={478.171}
          y1={-841.476}
          x2={426.709}
          y2={-874.12}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M473.97 219.69a.455.455 0 01-.22-.86l.43-.23c.22-.11.48-.02.6.19.12.22.04.49-.18.61h-.01l-.42.22c-.05.05-.13.07-.2.07z'
          fill='url(#banner_svg__SVGID_64_)'
        />
      </g>
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_65_'
        gradientUnits='userSpaceOnUse'
        x1={183.568}
        y1={-716.783}
        x2={488.428}
        y2={-701.788}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M411.69 131.02c-.02.42-.26.81-.62 1.03l-60.45 34.91c-.98.58-2.2.58-3.19 0l-61.65-35.45c-.41-.23-.65-.67-.62-1.13V36.6v-.1c.03-.41.26-.79.62-.99L346.23.61c.99-.57 2.2-.57 3.19 0l61.66 35.45c.2.11.35.27.46.47v94.42l.15.07z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_65_)'
      />
      <linearGradient
        id='banner_svg__SVGID_66_'
        gradientUnits='userSpaceOnUse'
        x1={189.112}
        y1={-771.45}
        x2={485.233}
        y2={-756.888}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M411.68 113.74v17.28c-.02.43-.25.81-.62 1.03l-60.44 34.9c-.99.57-2.2.57-3.19 0l-61.66-35.45c-.4-.24-.64-.68-.62-1.14v-17.21c0 .45.23.86.62 1.09l61.66 35.45c.99.57 2.2.57 3.19 0l60.44-34.94c.38-.22.61-.61.63-1.04l-.01.03z'
        fill='url(#banner_svg__SVGID_66_)'
      />
      <linearGradient
        id='banner_svg__SVGID_67_'
        gradientUnits='userSpaceOnUse'
        x1={428.432}
        y1={-730.542}
        x2={-199.558}
        y2={-791.454}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M411.05 112.6L349.4 77.15c-.99-.57-2.21-.57-3.2 0l-60.45 34.9c-.36.21-.59.58-.62.99v.1c0 .45.23.86.62 1.09l61.66 35.45c.99.57 2.2.57 3.19 0l60.45-34.93c.6-.35.81-1.12.47-1.72-.11-.19-.27-.36-.47-.47v.04z'
        fill='url(#banner_svg__SVGID_67_)'
      />
      <linearGradient
        id='banner_svg__SVGID_68_'
        gradientUnits='userSpaceOnUse'
        x1={409.365}
        y1={-747.714}
        x2={357.903}
        y2={-780.367}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M358.72 154.25c-.16 0-.31-.09-.39-.22a.464.464 0 01.16-.62l40.01-23.07c.22-.12.49-.05.62.16.12.22.04.49-.17.62l-40.01 23.07c-.07.04-.14.06-.22.06z'
        fill='url(#banner_svg__SVGID_68_)'
      />
      <linearGradient
        id='banner_svg__SVGID_69_'
        gradientUnits='userSpaceOnUse'
        x1={409.994}
        y1={-748.699}
        x2={358.532}
        y2={-781.352}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M402.17 129.2c-.17 0-.32-.1-.4-.24a.445.445 0 01.19-.6l.42-.22c.21-.13.49-.07.62.14.13.21.07.49-.14.62-.02.01-.04.02-.05.03l-.42.23a.78.78 0 01-.22.04z'
        fill='url(#banner_svg__SVGID_69_)'
      />
      <linearGradient
        id='banner_svg__SVGID_70_'
        gradientUnits='userSpaceOnUse'
        x1={410.088}
        y1={-748.845}
        x2={358.626}
        y2={-781.497}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M404.16 128.15a.455.455 0 01-.22-.86l.42-.22c.22-.12.49-.04.61.18s.04.49-.18.61h-.01l-.42.22c-.06.06-.13.07-.2.07z'
        fill='url(#banner_svg__SVGID_70_)'
      />
      <linearGradient
        id='banner_svg__SVGID_71_'
        gradientUnits='userSpaceOnUse'
        x1={410.151}
        y1={-748.952}
        x2={358.688}
        y2={-781.595}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M405.95 127.16a.455.455 0 01-.22-.86l.43-.22c.22-.11.48-.02.6.19.12.22.04.49-.18.61h-.01l-.42.23c-.06.04-.13.05-.2.05z'
        fill='url(#banner_svg__SVGID_71_)'
      />
      <linearGradient
        id='banner_svg__SVGID_72_'
        gradientUnits='userSpaceOnUse'
        x1={458.007}
        y1={-743.338}
        x2={342.932}
        y2={-745.921}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M406.04 97.4v15.74c-.02.38-.23.72-.56.9l-55.07 31.8c-.9.52-2 .52-2.9 0l-56.16-32.29c-.37-.21-.59-.61-.57-1.04V96.87c0 .41.22.79.58.99l56.16 32.29c.9.51 2 .51 2.9 0l55.1-31.8c.34-.19.56-.56.57-.95h-.05z'
        fill='url(#banner_svg__SVGID_72_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_73_'
          gradientUnits='userSpaceOnUse'
          x1={189.112}
          y1={-745.771}
          x2={485.233}
          y2={-731.2}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M411.68 88.06v17.28c-.02.42-.25.81-.62 1.02l-60.44 34.91c-.99.57-2.2.57-3.19 0l-61.66-35.45c-.4-.23-.64-.68-.62-1.14v-17.2c0 .45.23.86.62 1.09L347.43 124c.98.58 2.2.58 3.19 0l60.45-34.9c.38-.22.62-.61.63-1.04h-.02z'
          fill='url(#banner_svg__SVGID_73_)'
        />
        <linearGradient
          id='banner_svg__SVGID_74_'
          gradientUnits='userSpaceOnUse'
          x1={428.433}
          y1={-704.868}
          x2={-199.557}
          y2={-765.789}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={1} stopColor='#003cff' />
        </linearGradient>
        <path
          d='M411.05 86.92L349.4 51.47c-.99-.57-2.21-.57-3.2 0l-60.45 34.9c-.36.21-.59.58-.62 1v.1c0 .45.23.86.62 1.09L347.41 124c.98.58 2.2.58 3.19 0l60.45-34.9c.6-.34.81-1.1.47-1.7-.1-.2-.27-.36-.47-.48z'
          fill='url(#banner_svg__SVGID_74_)'
        />
        <linearGradient
          id='banner_svg__SVGID_75_'
          gradientUnits='userSpaceOnUse'
          x1={409.367}
          y1={-722.04}
          x2={357.904}
          y2={-754.683}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M358.72 128.57c-.25 0-.45-.21-.45-.45 0-.16.09-.3.22-.38l40.01-23.07a.45.45 0 11.53.73c-.02.02-.05.03-.08.05l-40.01 23.07a.51.51 0 01-.22.05z'
          fill='url(#banner_svg__SVGID_75_)'
        />
        <linearGradient
          id='banner_svg__SVGID_76_'
          gradientUnits='userSpaceOnUse'
          x1={409.964}
          y1={-722.975}
          x2={358.502}
          y2={-755.627}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M402.17 103.52c-.25.06-.5-.1-.55-.35s.1-.5.35-.55l.42-.22a.46.46 0 01.61.19c.11.22.03.49-.19.61l-.42.22c-.07.05-.14.09-.22.1z'
          fill='url(#banner_svg__SVGID_76_)'
        />
        <linearGradient
          id='banner_svg__SVGID_77_'
          gradientUnits='userSpaceOnUse'
          x1={410.089}
          y1={-723.169}
          x2={358.627}
          y2={-755.812}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M404.16 102.47c-.25 0-.45-.2-.45-.45 0-.17.09-.32.24-.4l.42-.23c.22-.12.5-.03.61.19.12.22.03.5-.19.61l-.42.22a.42.42 0 01-.21.06z'
          fill='url(#banner_svg__SVGID_77_)'
        />
        <linearGradient
          id='banner_svg__SVGID_78_'
          gradientUnits='userSpaceOnUse'
          x1={410.152}
          y1={-723.267}
          x2={358.689}
          y2={-755.919}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M405.95 101.48a.432.432 0 01-.43-.23.46.46 0 01.19-.61l.43-.22a.46.46 0 01.61.19c.11.22.03.49-.19.61l-.42.22c-.06.02-.13.04-.19.04z'
          fill='url(#banner_svg__SVGID_78_)'
        />
      </g>
      <linearGradient
        id='banner_svg__SVGID_79_'
        gradientUnits='userSpaceOnUse'
        x1={458.007}
        y1={-717.638}
        x2={342.932}
        y2={-720.23}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M406.04 71.72v15.74c-.02.38-.23.72-.56.9l-55.08 31.79c-.9.51-2 .51-2.9 0l-56.16-32.29c-.37-.21-.59-.61-.57-1.04V71.18c0 .41.22.79.58 1l56.16 32.28c.9.52 2 .52 2.9 0l55.11-31.79c.34-.2.56-.56.57-.95h-.05z'
        fill='url(#banner_svg__SVGID_79_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_80_'
          gradientUnits='userSpaceOnUse'
          x1={189.112}
          y1={-720.384}
          x2={485.233}
          y2={-705.822}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M411.68 62.68v17.28c-.01.43-.25.82-.62 1.03l-60.44 34.89c-.99.57-2.2.57-3.19 0l-61.66-35.45c-.4-.23-.64-.68-.62-1.14v-17.2c0 .45.23.86.62 1.08l61.66 35.45c.98.58 2.2.58 3.19 0l60.44-34.9c.37-.21.61-.61.62-1.04z'
          fill='url(#banner_svg__SVGID_80_)'
        />
        <linearGradient
          id='banner_svg__SVGID_81_'
          gradientUnits='userSpaceOnUse'
          x1={428.433}
          y1={-679.487}
          x2={-199.557}
          y2={-740.409}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={1} stopColor='#003cff' />
        </linearGradient>
        <path
          d='M411.05 61.54L349.4 26.09c-.99-.57-2.21-.57-3.2 0L285.75 61c-.36.21-.59.58-.62.99v.11c0 .45.23.86.62 1.08l61.66 35.45c.98.58 2.2.58 3.19 0l60.45-34.9c.38-.22.61-.61.63-1.04.03-.48-.22-.92-.63-1.15z'
          fill='url(#banner_svg__SVGID_81_)'
        />
        <linearGradient
          id='banner_svg__SVGID_82_'
          gradientUnits='userSpaceOnUse'
          x1={409.367}
          y1={-696.651}
          x2={357.905}
          y2={-729.303}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M358.72 103.19c-.25 0-.45-.21-.45-.45 0-.16.09-.3.22-.38l40.01-23.08c.22-.12.49-.04.62.17.12.22.04.49-.17.61l-40.01 23.07c-.06.04-.14.06-.22.06z'
          fill='url(#banner_svg__SVGID_82_)'
        />
        <linearGradient
          id='banner_svg__SVGID_83_'
          gradientUnits='userSpaceOnUse'
          x1={409.992}
          y1={-697.638}
          x2={358.53}
          y2={-730.282}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M402.17 78.13a.47.47 0 01-.4-.23.46.46 0 01.19-.61l.42-.23c.22-.12.5-.03.61.19.12.22.03.5-.19.61l-.42.22c-.06.04-.13.05-.21.05z'
          fill='url(#banner_svg__SVGID_83_)'
        />
        <linearGradient
          id='banner_svg__SVGID_84_'
          gradientUnits='userSpaceOnUse'
          x1={410.091}
          y1={-697.782}
          x2={358.628}
          y2={-730.434}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M404.16 77.09c-.17 0-.33-.09-.41-.24a.445.445 0 01.19-.6l.42-.22c.22-.12.49-.04.61.18v.01c.12.21.04.48-.18.6 0 0-.01 0-.01.01l-.42.22c-.06.02-.13.04-.2.04z'
          fill='url(#banner_svg__SVGID_84_)'
        />
        <linearGradient
          id='banner_svg__SVGID_85_'
          gradientUnits='userSpaceOnUse'
          x1={410.153}
          y1={-697.888}
          x2={358.69}
          y2={-730.541}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M405.95 76.1c-.17 0-.33-.09-.41-.24a.457.457 0 01.19-.6l.43-.23a.447.447 0 01.41.79l-.42.23c-.06.03-.13.05-.2.05z'
          fill='url(#banner_svg__SVGID_85_)'
        />
      </g>
      <linearGradient
        id='banner_svg__SVGID_86_'
        gradientUnits='userSpaceOnUse'
        x1={458.007}
        y1={-692.266}
        x2={342.932}
        y2={-694.849}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M406.04 46.35v15.72c-.02.38-.23.72-.56.9l-55.07 31.79c-.9.52-2 .52-2.9 0l-56.17-32.21c-.37-.21-.59-.62-.57-1.04V45.8c0 .41.22.79.58.99l56.16 32.29c.9.52 2 .52 2.9 0l55.11-31.83c.34-.19.56-.55.57-.95l-.05.05z'
        fill='url(#banner_svg__SVGID_86_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_87_'
          gradientUnits='userSpaceOnUse'
          x1={189.112}
          y1={-694.9}
          x2={485.233}
          y2={-680.338}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M411.68 37.19v17.26c-.02.43-.25.81-.62 1.03L350.62 90.4c-.99.57-2.2.57-3.19 0l-61.66-35.46c-.4-.23-.64-.67-.62-1.13V36.6c0 .45.23.86.62 1.09l61.66 35.45c.99.57 2.2.57 3.19 0l60.44-34.89c.37-.22.61-.61.62-1.06z'
          fill='url(#banner_svg__SVGID_87_)'
        />
        <linearGradient
          id='banner_svg__SVGID_88_'
          gradientUnits='userSpaceOnUse'
          x1={153.921}
          y1={-669.532}
          x2={428.712}
          y2={-658.561}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M411.68 37.14v.05a1.194 1.194 0 01-.62 1.05l-20.22 11.72-22.03 12.68-18.19 10.51c-.99.57-2.2.57-3.19 0l-19.4-11.16-22.04-12.66-20.22-11.63c-.39-.22-.63-.64-.63-1.09v-.1c.03-.41.26-.79.62-.99L346.21.62c.99-.57 2.2-.57 3.19 0l61.66 35.45c.2.11.35.27.46.47.1.18.16.38.16.6z'
          fill='url(#banner_svg__SVGID_88_)'
        />
        <linearGradient
          id='banner_svg__SVGID_89_'
          gradientUnits='userSpaceOnUse'
          x1={409.365}
          y1={-671.169}
          x2={357.903}
          y2={-703.821}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M358.72 77.71c-.16 0-.31-.09-.39-.23a.464.464 0 01.16-.62l40.01-23.07c.22-.12.49-.05.62.16.12.22.04.49-.17.62l-40.01 23.07a.44.44 0 01-.22.07z'
          fill='url(#banner_svg__SVGID_89_)'
        />
        <linearGradient
          id='banner_svg__SVGID_90_'
          gradientUnits='userSpaceOnUse'
          x1={409.994}
          y1={-672.154}
          x2={358.532}
          y2={-704.806}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M402.17 52.65c-.17 0-.32-.1-.4-.24a.445.445 0 01.19-.6l.42-.23c.21-.13.49-.07.62.14.13.21.07.49-.14.62-.02.01-.04.02-.05.03l-.42.23c-.07.03-.14.05-.22.05z'
          fill='url(#banner_svg__SVGID_90_)'
        />
        <linearGradient
          id='banner_svg__SVGID_91_'
          gradientUnits='userSpaceOnUse'
          x1={410.062}
          y1={-672.257}
          x2={358.6}
          y2={-704.91}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M404.16 51.61c-.25.06-.5-.09-.56-.34-.06-.25.09-.5.34-.56l.42-.23a.46.46 0 01.61.19c.12.22.04.49-.18.61h-.01l-.42.23c-.05.05-.12.09-.2.1z'
          fill='url(#banner_svg__SVGID_91_)'
        />
        <linearGradient
          id='banner_svg__SVGID_92_'
          gradientUnits='userSpaceOnUse'
          x1={410.151}
          y1={-672.406}
          x2={358.688}
          y2={-705.049}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M405.95 50.62a.455.455 0 01-.22-.86l.43-.23c.22-.11.48-.02.6.19.12.22.04.49-.18.61h-.01l-.42.23a.36.36 0 01-.2.06z'
          fill='url(#banner_svg__SVGID_92_)'
        />
      </g>
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_93_'
        gradientUnits='userSpaceOnUse'
        x1={11.767}
        y1={-725.571}
        x2={316.627}
        y2={-710.577}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M239.89 139.8c-.02.42-.26.81-.62 1.03l-60.45 34.91c-.98.58-2.2.58-3.19 0l-61.65-35.45c-.41-.23-.65-.67-.62-1.13V45.39v-.1c.03-.41.26-.79.62-.99l60.45-34.9c.99-.57 2.2-.57 3.19 0l61.66 35.45c.2.11.35.27.46.47v94.42l.15.06z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_93_)'
      />
      <linearGradient
        id='banner_svg__SVGID_94_'
        gradientUnits='userSpaceOnUse'
        x1={17.311}
        y1={-780.239}
        x2={313.432}
        y2={-765.677}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M239.88 122.53v17.28c-.02.43-.25.81-.62 1.03l-60.44 34.9c-.99.57-2.2.57-3.19 0l-61.66-35.45c-.4-.24-.64-.68-.62-1.14v-17.21c0 .45.23.86.62 1.09l61.66 35.45c.99.57 2.2.57 3.19 0l60.44-34.94c.38-.22.61-.61.63-1.04l-.01.03z'
        fill='url(#banner_svg__SVGID_94_)'
      />
      <linearGradient
        id='banner_svg__SVGID_95_'
        gradientUnits='userSpaceOnUse'
        x1={256.631}
        y1={-739.33}
        x2={-371.359}
        y2={-800.243}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M239.25 121.39L177.6 85.94c-.99-.57-2.21-.57-3.2 0l-60.45 34.9c-.36.21-.59.58-.62.99v.1c0 .45.23.86.62 1.09l61.66 35.45c.99.57 2.2.57 3.19 0l60.45-34.93c.6-.35.81-1.12.47-1.72-.11-.19-.27-.36-.47-.47v.04z'
        fill='url(#banner_svg__SVGID_95_)'
      />
      <linearGradient
        id='banner_svg__SVGID_96_'
        gradientUnits='userSpaceOnUse'
        x1={237.564}
        y1={-756.503}
        x2={186.102}
        y2={-789.155}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M186.92 163.04c-.16 0-.31-.09-.39-.23a.464.464 0 01.16-.62l40.01-23.07c.22-.12.49-.05.62.16.12.22.04.49-.17.62l-40.01 23.07a.36.36 0 01-.22.07z'
        fill='url(#banner_svg__SVGID_96_)'
      />
      <linearGradient
        id='banner_svg__SVGID_97_'
        gradientUnits='userSpaceOnUse'
        x1={238.194}
        y1={-757.488}
        x2={186.731}
        y2={-790.14}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M230.37 137.99c-.17 0-.32-.1-.4-.24a.445.445 0 01.19-.6l.42-.23c.21-.13.49-.07.62.14.13.21.07.49-.14.62-.02.01-.04.02-.05.03l-.42.22c-.07.04-.14.06-.22.06z'
        fill='url(#banner_svg__SVGID_97_)'
      />
      <linearGradient
        id='banner_svg__SVGID_98_'
        gradientUnits='userSpaceOnUse'
        x1={238.288}
        y1={-757.633}
        x2={186.825}
        y2={-790.286}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M232.36 136.94a.455.455 0 01-.22-.86l.42-.22c.22-.12.49-.04.61.18s.04.49-.18.61h-.01l-.42.23a.36.36 0 01-.2.06z'
        fill='url(#banner_svg__SVGID_98_)'
      />
      <linearGradient
        id='banner_svg__SVGID_99_'
        gradientUnits='userSpaceOnUse'
        x1={238.35}
        y1={-757.741}
        x2={186.888}
        y2={-790.384}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M234.15 135.95a.455.455 0 01-.22-.86l.43-.22c.22-.11.48-.02.6.19.12.22.04.49-.18.61h-.01l-.42.22a.36.36 0 01-.2.06z'
        fill='url(#banner_svg__SVGID_99_)'
      />
      <linearGradient
        id='banner_svg__SVGID_100_'
        gradientUnits='userSpaceOnUse'
        x1={286.206}
        y1={-752.127}
        x2={171.131}
        y2={-754.71}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M234.24 106.19v15.74c-.02.38-.23.72-.56.9l-55.07 31.8c-.9.52-2 .52-2.9 0l-56.16-32.29c-.37-.21-.59-.61-.57-1.04v-15.64c0 .41.22.79.58.99l56.16 32.29c.9.51 2 .51 2.9 0l55.1-31.8c.34-.19.56-.56.57-.95h-.05z'
        fill='url(#banner_svg__SVGID_100_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_101_'
          gradientUnits='userSpaceOnUse'
          x1={17.311}
          y1={-754.56}
          x2={313.432}
          y2={-739.989}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M239.88 96.85v17.28c-.02.42-.25.81-.62 1.02l-60.44 34.91c-.99.57-2.2.57-3.19 0l-61.66-35.45c-.4-.23-.64-.68-.62-1.14v-17.2c0 .45.23.86.62 1.09l61.66 35.44c.98.58 2.2.58 3.19 0l60.45-34.9c.38-.22.62-.61.63-1.04h-.02z'
          fill='url(#banner_svg__SVGID_101_)'
        />
        <linearGradient
          id='banner_svg__SVGID_102_'
          gradientUnits='userSpaceOnUse'
          x1={256.633}
          y1={-713.656}
          x2={-371.358}
          y2={-774.578}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={1} stopColor='#003cff' />
        </linearGradient>
        <path
          d='M239.25 95.71L177.6 60.26c-.99-.57-2.21-.57-3.2 0l-60.45 34.9c-.36.21-.59.58-.62 1v.1c0 .45.23.86.62 1.09l61.66 35.44c.98.58 2.2.58 3.19 0l60.45-34.9c.6-.34.81-1.1.47-1.7-.11-.2-.27-.37-.47-.48z'
          fill='url(#banner_svg__SVGID_102_)'
        />
        <linearGradient
          id='banner_svg__SVGID_103_'
          gradientUnits='userSpaceOnUse'
          x1={237.566}
          y1={-730.828}
          x2={186.103}
          y2={-763.472}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M186.92 137.36c-.25 0-.45-.21-.45-.45 0-.16.09-.3.22-.38l40.01-23.07a.45.45 0 11.53.73c-.02.02-.05.03-.08.05l-40.01 23.07a.51.51 0 01-.22.05z'
          fill='url(#banner_svg__SVGID_103_)'
        />
        <linearGradient
          id='banner_svg__SVGID_104_'
          gradientUnits='userSpaceOnUse'
          x1={238.163}
          y1={-731.763}
          x2={186.701}
          y2={-764.416}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M230.37 112.31c-.25.06-.5-.1-.55-.35-.06-.25.1-.5.35-.55l.42-.22a.46.46 0 01.61.19c.11.22.03.49-.19.61l-.42.23c-.07.04-.14.07-.22.09z'
          fill='url(#banner_svg__SVGID_104_)'
        />
        <linearGradient
          id='banner_svg__SVGID_105_'
          gradientUnits='userSpaceOnUse'
          x1={238.288}
          y1={-731.957}
          x2={186.826}
          y2={-764.601}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M232.36 111.26c-.25 0-.45-.2-.45-.45 0-.17.09-.32.24-.4l.42-.23c.22-.12.5-.03.61.19.12.22.03.5-.19.61l-.42.22a.42.42 0 01-.21.06z'
          fill='url(#banner_svg__SVGID_105_)'
        />
        <linearGradient
          id='banner_svg__SVGID_106_'
          gradientUnits='userSpaceOnUse'
          x1={238.351}
          y1={-732.056}
          x2={186.888}
          y2={-764.708}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M234.15 110.27a.432.432 0 01-.43-.23.46.46 0 01.19-.61l.43-.22a.46.46 0 01.61.19c.11.22.03.49-.19.61l-.42.22c-.07.02-.13.03-.19.04z'
          fill='url(#banner_svg__SVGID_106_)'
        />
      </g>
      <linearGradient
        id='banner_svg__SVGID_107_'
        gradientUnits='userSpaceOnUse'
        x1={286.206}
        y1={-726.426}
        x2={171.131}
        y2={-729.018}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M234.24 80.51v15.74c-.02.38-.23.72-.56.9l-55.08 31.79c-.9.51-2 .51-2.9 0l-56.16-32.29c-.37-.21-.59-.61-.57-1.04V79.97c0 .41.22.79.58 1l56.16 32.28c.9.52 2 .52 2.9 0l55.11-31.79c.34-.2.56-.56.57-.95h-.05z'
        fill='url(#banner_svg__SVGID_107_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_108_'
          gradientUnits='userSpaceOnUse'
          x1={17.311}
          y1={-729.172}
          x2={313.432}
          y2={-714.61}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M239.88 71.47v17.28c-.01.43-.25.82-.62 1.03l-60.44 34.89c-.99.57-2.2.57-3.19 0l-61.66-35.45c-.4-.23-.64-.68-.62-1.14v-17.2c0 .45.23.86.62 1.08l61.66 35.45c.98.58 2.2.58 3.19 0l60.44-34.9c.37-.22.61-.61.62-1.04z'
          fill='url(#banner_svg__SVGID_108_)'
        />
        <linearGradient
          id='banner_svg__SVGID_109_'
          gradientUnits='userSpaceOnUse'
          x1={256.633}
          y1={-688.276}
          x2={-371.358}
          y2={-749.198}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={1} stopColor='#003cff' />
        </linearGradient>
        <path
          d='M239.25 70.33L177.6 34.88c-.99-.57-2.21-.57-3.2 0l-60.45 34.9c-.36.21-.59.58-.62.99v.11c0 .45.23.86.62 1.08l61.66 35.45c.98.58 2.2.58 3.19 0l60.45-34.9c.38-.22.61-.61.63-1.04.03-.47-.22-.91-.63-1.14z'
          fill='url(#banner_svg__SVGID_109_)'
        />
        <linearGradient
          id='banner_svg__SVGID_110_'
          gradientUnits='userSpaceOnUse'
          x1={237.566}
          y1={-705.44}
          x2={186.104}
          y2={-738.092}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M186.92 111.98c-.25 0-.45-.21-.45-.45 0-.16.09-.3.22-.38l40.01-23.08c.22-.12.49-.04.62.17.12.22.04.49-.17.61l-40.01 23.07c-.06.04-.14.06-.22.06z'
          fill='url(#banner_svg__SVGID_110_)'
        />
        <linearGradient
          id='banner_svg__SVGID_111_'
          gradientUnits='userSpaceOnUse'
          x1={238.191}
          y1={-706.427}
          x2={186.729}
          y2={-739.07}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M230.37 86.92a.47.47 0 01-.4-.23.46.46 0 01.19-.61l.42-.22c.22-.12.5-.03.61.19.12.22.03.5-.19.61l-.42.22c-.06.03-.14.04-.21.04z'
          fill='url(#banner_svg__SVGID_111_)'
        />
        <linearGradient
          id='banner_svg__SVGID_112_'
          gradientUnits='userSpaceOnUse'
          x1={238.29}
          y1={-706.571}
          x2={186.828}
          y2={-739.223}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M232.36 85.88c-.17 0-.33-.09-.41-.24a.445.445 0 01.19-.6l.42-.22c.22-.12.49-.04.61.18v.01c.12.21.04.48-.18.6 0 0-.01 0-.01.01l-.42.23c-.06.01-.13.03-.2.03z'
          fill='url(#banner_svg__SVGID_112_)'
        />
        <linearGradient
          id='banner_svg__SVGID_113_'
          gradientUnits='userSpaceOnUse'
          x1={238.352}
          y1={-706.677}
          x2={186.89}
          y2={-739.329}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M234.15 84.89c-.17 0-.33-.09-.41-.24a.457.457 0 01.19-.6l.43-.23a.447.447 0 01.41.79l-.42.22a.36.36 0 01-.2.06z'
          fill='url(#banner_svg__SVGID_113_)'
        />
      </g>
      <linearGradient
        id='banner_svg__SVGID_114_'
        gradientUnits='userSpaceOnUse'
        x1={286.206}
        y1={-701.054}
        x2={171.131}
        y2={-703.638}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M234.24 55.14v15.72c-.02.38-.23.72-.56.9l-55.07 31.79c-.9.52-2 .52-2.9 0l-56.17-32.21c-.37-.21-.59-.62-.57-1.04V54.59c0 .41.22.79.58.99l56.16 32.29c.9.52 2 .52 2.9 0l55.11-31.83c.34-.19.56-.55.57-.95l-.05.05z'
        fill='url(#banner_svg__SVGID_114_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_115_'
          gradientUnits='userSpaceOnUse'
          x1={17.311}
          y1={-703.689}
          x2={313.432}
          y2={-689.126}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M239.88 45.98v17.26c-.02.43-.25.81-.62 1.03l-60.44 34.92c-.99.57-2.2.57-3.19 0l-61.66-35.46c-.4-.23-.64-.67-.62-1.13V45.4c0 .45.23.86.62 1.09l61.66 35.45c.99.57 2.2.57 3.19 0l60.44-34.89c.37-.23.61-.63.62-1.07z'
          fill='url(#banner_svg__SVGID_115_)'
        />
        <linearGradient
          id='banner_svg__SVGID_116_'
          gradientUnits='userSpaceOnUse'
          x1={-17.879}
          y1={-678.321}
          x2={256.911}
          y2={-667.35}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M239.88 45.92v.05a1.194 1.194 0 01-.62 1.05l-20.22 11.72-22.03 12.68-18.19 10.51c-.99.57-2.2.57-3.19 0l-19.4-11.16-22.04-12.66-20.22-11.63c-.39-.22-.63-.64-.63-1.09v-.1c.03-.41.26-.79.62-.99l60.45-34.9c.99-.57 2.2-.57 3.19 0l61.66 35.45c.2.11.35.27.46.47.1.18.16.39.16.6z'
          fill='url(#banner_svg__SVGID_116_)'
        />
        <linearGradient
          id='banner_svg__SVGID_117_'
          gradientUnits='userSpaceOnUse'
          x1={237.564}
          y1={-679.957}
          x2={186.102}
          y2={-712.61}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M186.92 86.5c-.16 0-.31-.09-.39-.23a.464.464 0 01.16-.62l40.01-23.07c.22-.12.49-.05.62.16.12.22.04.49-.17.62l-40.01 23.07c-.07.04-.14.06-.22.07z'
          fill='url(#banner_svg__SVGID_117_)'
        />
        <linearGradient
          id='banner_svg__SVGID_118_'
          gradientUnits='userSpaceOnUse'
          x1={238.194}
          y1={-680.942}
          x2={186.731}
          y2={-713.595}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M230.37 61.44c-.17 0-.32-.1-.4-.24a.445.445 0 01.19-.6l.42-.23c.21-.13.49-.07.62.14.13.21.07.49-.14.62-.02.01-.04.02-.05.03l-.42.23c-.07.03-.14.05-.22.05z'
          fill='url(#banner_svg__SVGID_118_)'
        />
        <linearGradient
          id='banner_svg__SVGID_119_'
          gradientUnits='userSpaceOnUse'
          x1={238.261}
          y1={-681.046}
          x2={186.799}
          y2={-713.698}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M232.36 60.4c-.25.06-.5-.09-.56-.34-.06-.25.09-.5.34-.56l.42-.23a.46.46 0 01.61.19c.12.22.04.49-.18.61h-.01l-.42.23a.42.42 0 01-.2.1z'
          fill='url(#banner_svg__SVGID_119_)'
        />
        <linearGradient
          id='banner_svg__SVGID_120_'
          gradientUnits='userSpaceOnUse'
          x1={238.35}
          y1={-681.195}
          x2={186.888}
          y2={-713.838}
          gradientTransform='matrix(1 0 0 -1 0 -624.889)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M234.15 59.41a.455.455 0 01-.22-.86l.43-.23c.22-.11.48-.02.6.19.12.22.04.49-.18.61h-.01l-.42.23a.36.36 0 01-.2.06z'
          fill='url(#banner_svg__SVGID_120_)'
        />
      </g>
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_121_'
        gradientUnits='userSpaceOnUse'
        x1={82.952}
        y1={-966.298}
        x2={280.792}
        y2={-907.824}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M229.95 315.66c0 .19-.06.37-.15.54-.09.19-.24.34-.43.44l-56.48 32.67c-.92.52-2.06.52-2.98 0l-57.58-33.12c-.37-.21-.6-.6-.59-1.02a.139.139 0 010-.09c.01-.11.05-.21.09-.3l1.86-4.48.44-1.09 2.45-5.88 14.85-.15 37.36-21.58c.92-.54 2.06-.54 2.98 0l35.69 20.52 4.2-.06 13.38 1.54 2.55 6.17.44 1.09 1.86 4.51c.04.08.06.18.06.29z'
        fill='url(#banner_svg__SVGID_121_)'
      />
      <path
        className='banner_svg__st124'
        d='M228.04 310.79l-54.86 31.68c-1.1.64-2.46.64-3.56 0l-55.98-32.19.44-1.09 56.12 32.27c.74.43 1.66.43 2.4 0l55.03-31.76.41 1.09z'
      />
      <linearGradient
        id='banner_svg__SVGID_122_'
        gradientUnits='userSpaceOnUse'
        x1={3.752}
        y1={-935.357}
        x2={239.814}
        y2={-925.936}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M225.19 304.05c0 .17-.05.34-.13.49-.1.17-.24.31-.41.41l-17.38 10.03-18.92 10.94-15.64 9.03c-.85.49-1.89.49-2.74 0l-16.67-9.54-18.92-10.88-17.4-9.99c-.34-.19-.54-.55-.54-.93a.139.139 0 010-.09c.03-.35.22-.67.52-.85l51.94-29.98c.85-.49 1.89-.49 2.74 0l53 30.48c.17.1.31.24.4.41.09.13.14.29.15.47z'
        fill='url(#banner_svg__SVGID_122_)'
      />
      <linearGradient
        id='banner_svg__SVGID_123_'
        gradientUnits='userSpaceOnUse'
        x1={120.608}
        y1={-943.273}
        x2={239.232}
        y2={-908.883}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M200.37 298.27l-28.93-16.63a1.96 1.96 0 00-1.96 0l-28.37 16.39h-8.98v5.6c-.01.02-.01.04 0 .06-.01.28.14.53.38.66l12.41 7.08 13.46 7.74 11.87 6.82c.6.35 1.34.35 1.94 0l11.13-6.43 13.47-7.78 12.37-7.14c.12-.07.22-.17.29-.29a.73.73 0 00.09-.35v-5.69l-9.17-.04z'
        fill='url(#banner_svg__SVGID_123_)'
      />
      <linearGradient
        id='banner_svg__SVGID_124_'
        gradientUnits='userSpaceOnUse'
        x1={-11.224}
        y1={-930.303}
        x2={211.34}
        y2={-921.417}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M209.52 298.32c0 .13-.03.25-.09.36a.78.78 0 01-.29.28l-12.37 7.15-13.47 7.77-11.13 6.43c-.6.35-1.34.35-1.94 0l-11.87-6.82-13.42-7.72-12.37-7.1a.74.74 0 01-.38-.66.085.085 0 010-.07c.02-.25.16-.48.37-.61l36.96-21.34a2.01 2.01 0 011.96 0l37.7 21.68c.12.06.22.16.28.28.05.11.07.24.06.37z'
        fill='url(#banner_svg__SVGID_124_)'
      />
      <path
        d='M209.52 298.32c0 .13-.03.25-.09.36a.78.78 0 01-.29.28l-12.37 7.15-13.47 7.77-11.13 6.43c-.6.35-1.34.35-1.94 0l-11.87-6.82-13.42-7.72-12.37-7.1a.74.74 0 01-.38-.66.085.085 0 010-.07c.02-.25.16-.48.37-.61l36.96-21.34a2.01 2.01 0 011.96 0l37.7 21.68c.12.06.22.16.28.28.05.11.07.24.06.37z'
        opacity={0.5}
        fill='#fff'
      />
      <linearGradient
        id='banner_svg__SVGID_125_'
        gradientUnits='userSpaceOnUse'
        x1={-55.095}
        y1={-931.799}
        x2={204.596}
        y2={-921.422}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M208.63 298.07c0 .12-.03.24-.08.35-.07.11-.16.21-.28.28l-12.09 6.99-13.16 7.6-10.88 6.28c-.59.34-1.32.34-1.91 0l-11.65-6.67-13.16-7.56-12.08-6.99a.713.713 0 01-.38-.65.064.064 0 010-.06c.02-.25.16-.47.37-.59l36.1-20.85c.59-.34 1.31-.34 1.9 0l36.85 21.18c.11.07.21.16.27.28.1.12.16.26.18.41z'
        fill='url(#banner_svg__SVGID_125_)'
      />
      <linearGradient
        id='banner_svg__SVGID_126_'
        gradientUnits='userSpaceOnUse'
        x1={186.822}
        y1={-911.781}
        x2={120.3}
        y2={-957.689}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M200.23 298.04a.65.65 0 01-.08.27.53.53 0 01-.21.21l-.13.08-.14.08-9.13 5.26-10.24 5.9-8.45 4.95c-.46.26-1.02.26-1.48 0l-9.01-5.18-10.23-5.82-9.07-5.3-.21-.12-.12-.07a.596.596 0 01-.29-.51c.01-.19.12-.36.28-.45l28.09-16.32a1.52 1.52 0 011.49 0l28.64 16.47c.09.06.16.13.21.22.05.1.08.21.08.33z'
        fill='url(#banner_svg__SVGID_126_)'
      />
      <linearGradient
        id='banner_svg__SVGID_127_'
        gradientUnits='userSpaceOnUse'
        x1={64.943}
        y1={-946.139}
        x2={222.314}
        y2={-912.495}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M199.67 298.7l-9.13 5.26-10.24 5.9-8.45 4.93c-.46.26-1.02.26-1.48 0l-9.01-5.18-10.23-5.82-9.07-5.3 27.82-16.07c.46-.27 1.03-.27 1.49 0l28.3 16.28z'
        fill='url(#banner_svg__SVGID_127_)'
      />
      <path
        d='M190.72 298.01c.36.21.48.68.27 1.04a.87.87 0 01-.27.27l-5.52 3.19-7.46 4.3-6.15 3.56c-.34.18-.74.18-1.08 0l-6.57-3.73-7.44-4.29-5.5-3.15a.763.763 0 01-.27-1.04c.07-.11.16-.2.27-.27l19.13-11.05c.34-.2.76-.2 1.09 0l19.5 11.17z'
        opacity={0.7}
        fill='none'
        stroke='#fff'
        strokeMiterlimit={10}
      />
      <linearGradient
        id='banner_svg__SVGID_128_'
        gradientUnits='userSpaceOnUse'
        x1={176.484}
        y1={-948.606}
        x2={182.761}
        y2={-948.606}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path fill='url(#banner_svg__SVGID_128_)' d='M176.48 323.51l3.47 2.05 2.81-1.56-3.46-2.12z' />
      <linearGradient
        id='banner_svg__SVGID_129_'
        gradientUnits='userSpaceOnUse'
        x1={183.134}
        y1={-944.769}
        x2={189.411}
        y2={-944.769}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path fill='url(#banner_svg__SVGID_129_)' d='M183.13 319.66l3.46 2.05 2.82-1.55-3.47-2.11z' />
      <linearGradient
        id='banner_svg__SVGID_130_'
        gradientUnits='userSpaceOnUse'
        x1={189.772}
        y1={-940.937}
        x2={196.061}
        y2={-940.937}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path fill='url(#banner_svg__SVGID_130_)' d='M189.77 315.83l3.47 2.05 2.82-1.55-3.47-2.12z' />
      <linearGradient
        id='banner_svg__SVGID_131_'
        gradientUnits='userSpaceOnUse'
        x1={196.422}
        y1={-937.094}
        x2={202.699}
        y2={-937.094}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path fill='url(#banner_svg__SVGID_131_)' d='M196.42 311.99l3.47 2.05 2.81-1.55-3.46-2.12z' />
      <linearGradient
        id='banner_svg__SVGID_132_'
        gradientUnits='userSpaceOnUse'
        x1={203.072}
        y1={-933.262}
        x2={209.349}
        y2={-933.262}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path fill='url(#banner_svg__SVGID_132_)' d='M203.07 308.16l3.46 2.05 2.82-1.55-3.47-2.12z' />
      <linearGradient
        id='banner_svg__SVGID_133_'
        gradientUnits='userSpaceOnUse'
        x1={169.801}
        y1={-948.646}
        x2={176.09}
        y2={-948.646}
        gradientTransform='rotate(180 167.879 -312.444)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path fill='url(#banner_svg__SVGID_133_)' d='M165.94 323.54l-3.45 2.05-2.82-1.55 3.47-2.12z' />
      <linearGradient
        id='banner_svg__SVGID_134_'
        gradientUnits='userSpaceOnUse'
        x1={176.451}
        y1={-944.803}
        x2={182.728}
        y2={-944.803}
        gradientTransform='rotate(180 167.879 -312.444)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path fill='url(#banner_svg__SVGID_134_)' d='M159.31 319.7l-3.47 2.05-2.82-1.55 3.47-2.12z' />
      <linearGradient
        id='banner_svg__SVGID_135_'
        gradientUnits='userSpaceOnUse'
        x1={183.101}
        y1={-940.972}
        x2={189.378}
        y2={-940.972}
        gradientTransform='rotate(180 167.879 -312.444)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path fill='url(#banner_svg__SVGID_135_)' d='M152.66 315.87l-3.47 2.05-2.81-1.55 3.46-2.12z' />
      <linearGradient
        id='banner_svg__SVGID_136_'
        gradientUnits='userSpaceOnUse'
        x1={189.739}
        y1={-937.129}
        x2={196.028}
        y2={-937.129}
        gradientTransform='rotate(180 167.879 -312.444)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path fill='url(#banner_svg__SVGID_136_)' d='M146.01 312.02l-3.46 2.05-2.82-1.54 3.47-2.12z' />
      <linearGradient
        id='banner_svg__SVGID_137_'
        gradientUnits='userSpaceOnUse'
        x1={196.389}
        y1={-933.297}
        x2={202.666}
        y2={-933.297}
        gradientTransform='rotate(180 167.879 -312.444)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path fill='url(#banner_svg__SVGID_137_)' d='M139.37 308.19l-3.47 2.05-2.82-1.55 3.47-2.12z' />
      <linearGradient
        id='banner_svg__SVGID_138_'
        gradientUnits='userSpaceOnUse'
        x1={170.836}
        y1={-1116.015}
        x2={170.836}
        y2={-857.64}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.6} />
        <stop offset={1} stopColor='#d9eaff' stopOpacity={0} />
      </linearGradient>
      <path
        d='M199.94 235.51l-28.64-16.47a1.52 1.52 0 00-1.49 0l-28.08 16.22c-.14.14-.24.31-.29.5v61.97c0 .21.11.41.29.51l.12.07.21.12 9.07 5.22 10.23 5.82 9.01 5.32c.46.26 1.02.26 1.48 0l8.45-4.89 10.24-5.9 9.13-5.26.14-.08.13-.08a.53.53 0 00.21-.21c.05-.08.08-.17.08-.27v-62.05a2.35 2.35 0 00-.29-.54z'
        fill='url(#banner_svg__SVGID_138_)'
      />
      <linearGradient
        id='banner_svg__SVGID_139_'
        gradientUnits='userSpaceOnUse'
        x1={171.214}
        y1={-1074.392}
        x2={171.214}
        y2={-871.705}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.6} />
        <stop offset={1} stopColor='#d9eaff' stopOpacity={0} />
      </linearGradient>
      <path
        d='M194.04 248.98l-22.46-12.92c-.36-.21-.81-.21-1.17 0l-22.03 12.73c-.11.11-.19.24-.23.39v48.61c0 .17.09.32.23.4l.09.05.16.09 7.12 4.09 8.02 4.57 7.07 4.18c.36.2.8.2 1.16 0l6.63-3.84 8.03-4.63 7.16-4.13.11-.06.1-.06c.07-.04.13-.09.16-.16.04-.06.06-.14.06-.21V249.4a1.04 1.04 0 00-.21-.42z'
        fill='url(#banner_svg__SVGID_139_)'
      />
      <linearGradient
        id='banner_svg__SVGID_140_'
        gradientUnits='userSpaceOnUse'
        x1={171.214}
        y1={-1034.134}
        x2={171.214}
        y2={-877.84}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.6} />
        <stop offset={1} stopColor='#d9eaff' stopOpacity={0} />
      </linearGradient>
      <path
        d='M188.82 254.62l-17.32-9.96a.904.904 0 00-.9 0l-16.98 9.81c-.09.08-.15.19-.18.3v37.48c0 .13.07.25.18.31l.07.04.13.07 5.49 3.16 6.19 3.52 5.45 3.22c.28.16.62.16.89 0l5.11-2.96 6.19-3.57 5.52-3.18.08-.05.08-.05c.05-.03.1-.07.13-.13.03-.05.05-.1.05-.16v-37.53c-.05-.11-.11-.22-.18-.32z'
        fill='url(#banner_svg__SVGID_140_)'
      />
      <linearGradient
        id='banner_svg__SVGID_141_'
        gradientUnits='userSpaceOnUse'
        x1={167.497}
        y1={-826.582}
        x2={168.17}
        y2={-920.33}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#00bfa5' />
        <stop offset={0.5} stopColor='#6dd9ca' />
        <stop offset={1} stopColor='#b5f2ea' />
      </linearGradient>
      <path
        d='M167.87 300.77c-.28 0-.51-.23-.51-.51v-93.84c-.04-.28.16-.54.44-.57.28-.04.54.16.57.44.01.04.01.09 0 .14v93.84c0 .26-.22.49-.5.5z'
        fill='url(#banner_svg__SVGID_141_)'
      />
      <linearGradient
        id='banner_svg__SVGID_142_'
        gradientUnits='userSpaceOnUse'
        x1={171.348}
        y1={-828.826}
        x2={172.021}
        y2={-922.575}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#00bfa5' />
        <stop offset={0.5} stopColor='#6dd9ca' />
        <stop offset={1} stopColor='#b5f2ea' />
      </linearGradient>
      <path
        d='M171.71 303.01c-.28 0-.5-.23-.5-.51v-93.84c-.04-.28.16-.54.44-.57.28-.04.54.16.57.44.01.04.01.09 0 .14v93.84c0 .27-.23.5-.51.5z'
        fill='url(#banner_svg__SVGID_142_)'
      />
      <linearGradient
        id='banner_svg__SVGID_143_'
        gradientUnits='userSpaceOnUse'
        x1={174.776}
        y1={-840.916}
        x2={175.373}
        y2={-922.93}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#00bfa5' />
        <stop offset={0.5} stopColor='#6dd9ca' />
        <stop offset={1} stopColor='#b5f2ea' />
      </linearGradient>
      <path
        d='M175.11 302.7c-.25 0-.45-.2-.45-.45v-82.09c0-.25.2-.45.45-.45s.44.2.45.45v82.09c-.01.25-.21.44-.45.45z'
        fill='url(#banner_svg__SVGID_143_)'
      />
      <linearGradient
        id='banner_svg__SVGID_144_'
        gradientUnits='userSpaceOnUse'
        x1={185.202}
        y1={-829.34}
        x2={150.346}
        y2={-775.069}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M233.43 227.14c0 3.56-1.27 6.11-3.4 7.4l-16.8 9.58c1.93-1.36 3.08-3.82 3.08-7.19 0-8.12-6.59-18.51-14.69-23.27-.33-.18-.65-.35-.97-.51-2.79-11.03-11.5-22.97-21.82-28.99a29.85 29.85 0 00-5.75-2.63c-3.98-1.28-7.52-1.26-10.33-.09-1.37.58-2.59 1.45-3.58 2.56-3.96-1.82-7.5-2.05-10.18-.89a7.624 7.624 0 00-4.12 4.45c-.28-.16-.52-.37-.78-.53-3.35-1.94-6.37-2.25-8.42-1.11l17.05-9.74c1.9-1.1 4.63-.96 7.73.63.25.13.51.28.76.43.25.15.53.36.79.54.37-1.02.94-1.95 1.68-2.74l.07-.07.09-.1c2.6-2.6 6.89-3.03 11.87-.9.19.07.37.16.56.25.58-.66 1.25-1.24 1.99-1.72.15-.1.3-.2.47-.28l.14-.09c1.5-.81 3.16-1.26 4.87-1.34 3.53-.2 7.73.94 12.22 3.56 10.32 6.01 19.02 17.94 21.82 28.99.33.15.63.31.95.5 8.13 4.79 14.7 15.18 14.7 23.3z'
        fill='url(#banner_svg__SVGID_144_)'
      />
      <linearGradient
        id='banner_svg__SVGID_145_'
        gradientUnits='userSpaceOnUse'
        x1={171.565}
        y1={-820.962}
        x2={163.849}
        y2={-795.677}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M183.72 170.84c-1.7.08-3.36.53-4.87 1.34l-.14.09c-.16.08-.32.18-.47.28-.74.48-1.4 1.06-1.99 1.72-.19-.09-.37-.17-.56-.25-4.31-1.83-8.09-1.76-10.71 0l-16 9.18-1.05.57s14.41 5.89 14.76 5.53c.18-.17 5.24-3.93 10.38-7.7l10.68-7.85c.06-.97.04-1.94-.03-2.91z'
        fill='url(#banner_svg__SVGID_145_)'
      />
      <linearGradient
        id='banner_svg__SVGID_146_'
        gradientUnits='userSpaceOnUse'
        x1={232.688}
        y1={-891.653}
        x2={171.879}
        y2={-784.244}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M217.79 203.39l-17.14 9.78-18.53-20.63L162 181.9l.73-.41 12.97-7.45 2.55-1.45.2-.12.26-.17.14-.09c4.18-2.31 10.28-1.75 17.09 2.23 10.33 5.98 19.02 17.87 21.85 28.95z'
        fill='url(#banner_svg__SVGID_146_)'
      />
      <linearGradient
        id='banner_svg__SVGID_147_'
        gradientUnits='userSpaceOnUse'
        x1={-0.719}
        y1={-837.879}
        x2={286.39}
        y2={-837.879}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M201.62 213.68c-.33-.19-.65-.35-.97-.51-2.8-11.04-11.5-22.97-21.82-28.99-8.42-4.9-15.73-4.6-19.69-.14-6.76-3.1-12.28-1.6-14.27 3.56-.28-.16-.54-.37-.79-.53-3.35-1.95-6.36-2.25-8.42-1.11-1.75.95-2.82 2.91-2.82 5.78 0 6.2 5.05 14.16 11.24 17.77l57.54 33.55c4.19 2.45 8 2.91 10.66 1.62.33-.17.65-.35.95-.56 1.92-1.36 3.08-3.82 3.08-7.19 0-8.13-6.6-18.51-14.69-23.25z'
        fill='url(#banner_svg__SVGID_147_)'
      />
      <linearGradient
        id='banner_svg__SVGID_148_'
        gradientUnits='userSpaceOnUse'
        x1={228.712}
        y1={-881.988}
        x2={208.343}
        y2={-815.201}
        gradientTransform='matrix(1 0 0 -1 0 -624.889)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M233.43 227.14c0 3.56-1.27 6.11-3.4 7.4l-16.8 9.58c1.93-1.36 3.08-3.82 3.08-7.19 0-8.12-6.59-18.51-14.69-23.27-.33-.18-.65-.35-.97-.51l17.14-9.78c.33.15.63.31.95.5 8.12 4.76 14.69 15.15 14.69 23.27z'
        fill='url(#banner_svg__SVGID_148_)'
      />
      <text
        transform='rotate(30.609 -295.202 364.03)'
        className='banner_svg__st124'
        fontSize={18}
        fontFamily='OpenSans-Bold'
      >
        {'RaySSH'}
      </text>
    </g>
  </svg>
)

export default SvgBanner
