import React from 'react'

const SvgTheory = props => (
  <svg id='theory_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 981.25 115.67' xmlSpace='preserve' {...props}>
    <style>
      {
        '.theory_svg__st1{fill:#6f7c8d}.theory_svg__st2{font-family:&apos;SourceHanSansCN-Normal-GBpc-EUC-H&apos;}.theory_svg__st3{font-size:13px}.theory_svg__st4{fill:#252a3a}.theory_svg__st5{font-size:14px}'
      }
    </style>
    <path
      d='M515.02 63.96c-2.43-3.7-6.23-6.13-10.49-6.74-2.34-5.92-7.89-9.71-14.29-9.71-6.41 0-11.96 3.79-14.29 9.71-7.65 1.05-13.3 7.75-12.97 15.68.33 8.05 7.55 14.61 15.6 14.61h23.28c6.8 0 12.94-4.36 14.91-10.6 1.41-4.4.76-9.12-1.75-12.95zm-27.01 15.46c0 .46-.37.84-.84.84h-.45c-.46 0-.84-.37-.84-.84V65.46l-2.85 2.85c-.33.33-.86.33-1.18 0l-.32-.32a.827.827 0 010-1.18l4.41-4.41c.09-.27.31-.46.59-.53.26-.16.58-.16.85 0 .3.07.52.3.6.6.15.24.16.54.04.79v16.16zm10.47-4.15l-4.41 4.41c-.09.27-.31.46-.59.53-.26.16-.58.16-.85 0-.3-.07-.52-.3-.6-.6a.823.823 0 01-.04-.79V62.67c0-.46.37-.84.84-.84h.45c.46 0 .84.37.84.84v13.96l2.85-2.86c.33-.33.86-.33 1.18 0l.32.32c.34.33.34.85.01 1.18z'
      fill='#fd5c1f'
    />
    <text transform='translate(232.419 52.433)' className='theory_svg__st1 theory_svg__st2 theory_svg__st3'>
      {'\u956D\u901F\u534F\u8BAE'}
    </text>
    <text transform='translate(682.419 52.433)' className='theory_svg__st1 theory_svg__st2 theory_svg__st3'>
      {'\u956D\u901F\u534F\u8BAE'}
    </text>
    <text transform='translate(883.888 109.433)' className='theory_svg__st4 theory_svg__st2 theory_svg__st5'>
      {'RaySSH\u670D\u52A1\u5668'}
    </text>
    <text transform='translate(4.191 109.433)' className='theory_svg__st4 theory_svg__st2 theory_svg__st5'>
      {'RaySSH\u5BA2\u6237\u7AEF'}
    </text>
    <path
      className='theory_svg__st4'
      d='M53.74 60.52v4.9c1.86.67 4.36 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21V59.2c-1.97 1.51-5.92 2.31-9.7 2.31-2.6-.01-5.03-.36-6.98-.99zM51.02 50.74c.03 1.51 4.16 3.2 9.7 3.2 5.55 0 9.7-1.7 9.7-3.21s-4.15-3.21-9.7-3.21c-5.54 0-9.69 1.69-9.7 3.21v.01zM30.42 72.82v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49v-4.49c-2.16 1.67-6.68 2.56-11.01 2.56-4.34-.01-8.87-.89-11.02-2.56z'
    />
    <path
      className='theory_svg__st4'
      d='M30.42 66.1v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49V66.1c-2.16 1.67-6.68 2.56-11.01 2.56-4.34 0-8.87-.89-11.02-2.56zM53.74 66.79v4.9c1.86.67 4.36 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21v-4.08c-1.97 1.51-5.92 2.31-9.7 2.31-2.6-.01-5.03-.35-6.98-.98z'
    />
    <path
      className='theory_svg__st4'
      d='M30.42 59.39v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49v-4.49c-2.16 1.67-6.68 2.56-11.01 2.56-4.34-.01-8.87-.89-11.02-2.56zM60.72 55.23c-3.78 0-7.72-.8-9.7-2.31v1.17c1.66.77 2.72 1.8 2.72 3.07v1.99c1.86.67 4.36 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21v-4.08c-1.97 1.51-5.92 2.31-9.7 2.31zM53.74 73.06v4.9c1.86.67 4.36 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21v-4.07c-1.97 1.51-5.92 2.31-9.7 2.31-2.6-.01-5.03-.36-6.98-.99zM30.42 79.53v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49v-4.49c-2.16 1.67-6.68 2.56-11.01 2.56-4.34 0-8.87-.89-11.02-2.56z'
    />
    <path
      className='theory_svg__st4'
      d='M41.43 53.68c-6.3 0-11.02 1.84-11.02 3.49 0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49.01-1.65-4.7-3.49-11.01-3.49z'
    />
    <g>
      <path className='theory_svg__st4' d='M104.9 67.51h340M104.9 66.51h340v2h-340z' />
    </g>
    <g>
      <path className='theory_svg__st4' d='M539.4 67.51h350M539.4 66.51h350v2h-350z' />
    </g>
    <g>
      <text transform='translate(441.395 22.466)' className='theory_svg__st4 theory_svg__st2' fontSize={18}>
        {'\u956D\u901FRaySSH'}
      </text>
    </g>
    <g>
      <path
        className='theory_svg__st4'
        d='M941.89 83.51c-.91-.05-1.61-.83-1.57-1.74s.79-1.63 1.7-1.61c.91.02 1.64.76 1.64 1.67-.01.46-.2.9-.53 1.21-.33.32-.78.49-1.24.47m-5.7 0c-.91-.05-1.61-.83-1.57-1.74s.79-1.63 1.7-1.61c.91.02 1.64.76 1.64 1.67-.01.46-.2.9-.53 1.21-.33.32-.78.49-1.24.47zm-20.32-3.33h10v3.32h-10v-3.32zm-2.7-3.99h-3v9.32a2.037 2.037 0 002.15 2h35.62c.55.02 1.09-.18 1.49-.55.4-.38.64-.89.66-1.45v-9.32h-36.92zm28.79-21.33a1.83 1.83 0 01-1.95-1.6c.04-.93.77-1.67 1.7-1.72.94-.02 1.73.67 1.85 1.6-.01.9-.7 1.65-1.6 1.72zm-5.7 0a1.97 1.97 0 01-1.65-.8c-.32-.51-.32-1.16 0-1.67.41-.65 1.18-.97 1.93-.81.75.16 1.32.77 1.42 1.53.03.46-.14.92-.46 1.25s-.77.52-1.24.5zm-20.39-3.35h10v3.35h-10v-3.35zm31.22-4h-33.87c-1.6-.04-2.93 1.2-3 2.8v8.55h39.99v-8.55a3.03 3.03 0 00-3.12-2.8zm-31.22 18.68h10v3.32h-10v-3.32zm20.24 0c.7-.01 1.34.38 1.65 1 .29.61.15 1.34-.35 1.8-.53.46-1.26.61-1.92.4-.65-.24-1.1-.83-1.15-1.52.06-.94.84-1.67 1.77-1.68zm5.7 0c.94-.02 1.73.67 1.85 1.6-.04.93-.77 1.67-1.7 1.72-.94.02-1.73-.67-1.85-1.6.02-.94.77-1.7 1.7-1.72zm-31.64 7.32h39.84V62.19h-39.99l.15 11.32zm0 0'
      />
    </g>
  </svg>
)

export default SvgTheory
