import React from 'react'

const SvgPoint2 = props => (
  <svg viewBox='0 0 50 50' {...props}>
    <path d='M43 43v-4l6 6H1V10l2 2v31h40zM3 5h46v35l-2-2V7H7v4L1 5h2z' fill='#334966' />
    <circle cx={19} cy={25} r={9} fill='#fd5c1f' />
    <circle cx={31} cy={25} r={8} fill='none' stroke='#334966' strokeWidth={2} />
  </svg>
)

export default SvgPoint2
