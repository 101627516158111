import React from 'react'

const SvgTable = props => (
  <svg id='table_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 500 140' xmlSpace='preserve' {...props}>
    <style>
      {
        '.table_svg__st1{fill:none;stroke:#9aa9b2;stroke-miterlimit:10}.table_svg__st2{fill:#182940}.table_svg__st3{font-family:&apos;SourceHanSansCN-Normal-GBpc-EUC-H&apos;}.table_svg__st4{font-size:12px}.table_svg__st5{font-family:&apos;OpenSans&apos;}'
      }
    </style>
    <path fill='#d3d9dd' stroke='#9aa9b2' strokeMiterlimit={10} d='M.48 4.75H499.5v26H.48z' />
    <path className='table_svg__st1' d='M.48 30.75H499.5v52H.48z' />
    <path className='table_svg__st1' d='M85.31 56.75H499.5v26H85.31zM85.31 82.75H499.5v26H85.31z' />
    <path className='table_svg__st1' d='M.48 82.75H499.5v52H.48zM85.31 31.25v103M167.15 5.25v130M324.84 5.25v130' />
    <text transform='matrix(.998 0 0 1 10.96 21.25)' className='table_svg__st2 table_svg__st3 table_svg__st4'>
      {'\u6D4B\u8BD5\u573A\u666F'}
    </text>
    <text transform='matrix(.998 0 0 1 177.633 22.25)' className='table_svg__st2 table_svg__st3 table_svg__st4'>
      {'\u4F20\u8F93\u5B8C\u6210\u65F6\u95F4\uFF08\u79D2\uFF09'}
    </text>
    <text transform='matrix(.998 0 0 1 336.321 23.25)' className='table_svg__st2 table_svg__st3 table_svg__st4'>
      {'\u4F20\u8F93\u5E73\u5747\u901F\u5EA6\uFF08KB/\u79D2\uFF09'}
    </text>
    <text transform='matrix(.998 0 0 1 10.96 61.25)' className='table_svg__st2 table_svg__st3 table_svg__st4'>
      {'\u4E0A\u4F20'}
    </text>
    <text transform='matrix(.998 0 0 1 94.796 47.25)' className='table_svg__st2 table_svg__st5 table_svg__st4'>
      {'rsync'}
    </text>
    <text transform='matrix(.998 0 0 1 94.796 73.583)' className='table_svg__st2 table_svg__st5 table_svg__st4'>
      {'Raysync'}
    </text>
    <text transform='matrix(.998 0 0 1 94.796 99.917)' className='table_svg__st2 table_svg__st5 table_svg__st4'>
      {'rsync'}
    </text>
    <text transform='matrix(.998 0 0 1 94.796 126.25)' className='table_svg__st2 table_svg__st5 table_svg__st4'>
      {'Raysync'}
    </text>
    <text transform='matrix(.998 0 0 1 177.633 48.25)' className='table_svg__st2 table_svg__st5 table_svg__st4'>
      {'9198'}
    </text>
    <text transform='matrix(.998 0 0 1 177.633 74.583)' className='table_svg__st2 table_svg__st5 table_svg__st4'>
      {'104'}
    </text>
    <text transform='matrix(.998 0 0 1 177.633 100.917)' className='table_svg__st2 table_svg__st5 table_svg__st4'>
      {'7767'}
    </text>
    <text transform='matrix(.998 0 0 1 177.633 127.25)' className='table_svg__st2 table_svg__st5 table_svg__st4'>
      {'99'}
    </text>
    <text transform='matrix(.998 0 0 1 336.321 49.25)' className='table_svg__st2 table_svg__st5 table_svg__st4'>
      {'114'}
    </text>
    <text transform='matrix(.998 0 0 1 336.321 75.583)' className='table_svg__st2 table_svg__st5 table_svg__st4'>
      {'9800'}
    </text>
    <text transform='matrix(.998 0 0 1 336.321 101.917)' className='table_svg__st2 table_svg__st5 table_svg__st4'>
      {'135'}
    </text>
    <text transform='matrix(.998 0 0 1 336.321 128.25)' className='table_svg__st2 table_svg__st5 table_svg__st4'>
      {'10300'}
    </text>
    <text transform='matrix(.998 0 0 1 10.96 107.25)' className='table_svg__st2 table_svg__st3 table_svg__st4'>
      {'\u4E0B\u8F7D'}
    </text>
  </svg>
)

export default SvgTable
