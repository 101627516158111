import React from 'react'

const SvgFeature3 = props => (
  <svg id='feature3_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 60 60' xmlSpace='preserve' {...props}>
    <style>{'.feature3_svg__st1{fill:#fff}'}</style>
    <circle cx={30} cy={30} r={30} fill='#fd5c1f' />
    <path
      className='feature3_svg__st1'
      d='M44.09 19.4l-12.21-6.96c-1.03-.59-2.73-.59-3.75 0L15.91 19.4c-1.06.6-1.89 2.03-1.89 3.24v14.7c0 1.22.83 2.64 1.89 3.24l12.21 6.96c.52.3 1.18.46 1.88.46s1.36-.16 1.88-.45l12.21-6.96c1.06-.6 1.89-2.03 1.89-3.24v-14.7c-.01-1.23-.83-2.65-1.89-3.25zm.2 17.94c0 .62-.49 1.47-1.04 1.78l-12.21 6.96c-.52.3-1.57.3-2.09 0l-12.21-6.96c-.54-.31-1.04-1.16-1.04-1.78v-14.7c0-.62.49-1.47 1.04-1.78l12.21-6.96c.26-.15.64-.23 1.04-.23s.78.08 1.04.23l12.21 6.96c.54.31 1.04 1.16 1.04 1.78v14.7z'
    />
    <path
      className='feature3_svg__st1'
      d='M29.99 37.2c-1.02 0-1.84.83-1.84 1.84 0 1.02.83 1.84 1.84 1.84 1.02 0 1.84-.83 1.84-1.84 0-1.01-.83-1.84-1.84-1.84zm0 2.85c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zM38.52 33.3c-.55-.32-1.23-.22-1.67.19l-4.64-2.68c.09-.25.15-.52.15-.8s-.06-.55-.15-.8l4.19-2.42-.42-.73-4.19 2.42c-.35-.41-.83-.7-1.38-.8v-5.35c.58-.18 1-.71 1-1.35a1.419 1.419 0 10-2.84 0c0 .64.42 1.17 1 1.35v5.35c-.55.1-1.03.39-1.38.8L24 26.06l-.42.73 4.19 2.42c-.09.25-.15.52-.15.8s.06.55.15.8l-4.64 2.68c-.45-.41-1.12-.51-1.67-.19a1.43 1.43 0 00-.52 1.95c.39.68 1.26.91 1.95.52.55-.32.8-.95.67-1.55l4.64-2.68c.35.41.83.71 1.38.8v4.83h.84v-4.83c.55-.1 1.03-.39 1.38-.8l4.64 2.68c-.13.59.12 1.23.67 1.55.68.39 1.55.16 1.95-.52.38-.69.14-1.56-.54-1.95z'
    />
    <path
      className='feature3_svg__st1'
      d='M21.24 27.09c.28.16.6.25.92.25a1.845 1.845 0 00.93-3.44c-.85-.49-2.03-.17-2.52.68-.51.88-.21 2.01.67 2.51zm.05-2.1c.18-.31.51-.5.87-.5.17 0 .35.05.5.13.48.28.64.89.37 1.37-.27.46-.91.64-1.37.37-.48-.27-.64-.89-.37-1.37zM36.89 23.9a1.849 1.849 0 00-.68 2.52c.33.57.94.92 1.6.92.32 0 .64-.09.92-.25a1.849 1.849 0 00.68-2.52c-.49-.84-1.67-1.16-2.52-.67zm1.89 1.86c-.07.26-.24.48-.47.61-.46.27-1.1.09-1.37-.37a.998.998 0 01.87-1.5 1.004 1.004 0 01.97 1.26z'
    />
  </svg>
)

export default SvgFeature3
