import React from 'react'

const SvgPoint1 = props => (
  <svg id='point1_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.point1_svg__st0{fill:none;stroke:#334966;stroke-width:2}'}</style>
    <g id='point1_svg__\u591A\u5B58\u50A8\u7C7B\u578B-48x48'>
      <circle id='point1_svg__Oval-7_2_' className='point1_svg__st0' cx={24.67} cy={25} r={23} />
      <ellipse id='point1_svg__Oval-13' className='point1_svg__st0' cx={24.67} cy={31} rx={11} ry={6} />
      <ellipse id='point1_svg__Oval-13-Copy' cx={24.67} cy={25} rx={12} ry={7} fill='#fd5c1f' />
      <ellipse id='point1_svg__Oval-13-Copy-2' className='point1_svg__st0' cx={24.67} cy={19} rx={11} ry={6} />
    </g>
  </svg>
)

export default SvgPoint1
