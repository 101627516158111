import React from 'react'

const SvgFeature1 = props => (
  <svg id='feature1_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 60 60' xmlSpace='preserve' {...props}>
    <style>{'.feature1_svg__st1{fill:#fff}'}</style>
    <circle cx={30} cy={30} r={30} fill='#fd5c1f' />
    <path
      className='feature1_svg__st1'
      d='M45.91 22.63c0-1.19-.84-2.64-1.87-3.23l-12.17-6.96c-1.02-.58-2.72-.58-3.74 0L15.97 19.4c-1.05.6-1.88 2.03-1.88 3.24v14.71c0 1.19.85 2.65 1.88 3.24l12.16 6.96c.51.29 1.18.45 1.87.45s1.36-.16 1.87-.45l12.16-6.96c1.03-.59 1.88-2.05 1.88-3.24V22.63zm-1.63 14.75c0 .65-.46 1.45-1.03 1.77l-12.2 6.98c-.52.3-1.57.3-2.09 0l-12.2-6.98c-.57-.32-1.03-1.12-1.03-1.77V22.61c0-.65.46-1.45 1.03-1.77l12.2-6.98c.26-.15.64-.23 1.04-.23s.78.09 1.04.23l12.2 6.98c.57.32 1.03 1.13 1.03 1.78v14.76z'
    />
    <path
      className='feature1_svg__st1'
      d='M23.91 26.63h10.42l-2.46 2.57a.41.41 0 00.01.58c.08.08.18.11.28.11.11 0 .21-.04.29-.13l3.11-3.26a.41.41 0 000-.58l-3.11-3.26a.399.399 0 00-.57-.01.41.41 0 00-.01.58l2.46 2.57H23.91c-.22 0-.41.18-.41.41 0 .24.18.42.41.42zM21.64 25.82h-1.62c-.22 0-.41.18-.41.41 0 .23.18.41.41.41h1.62c.22 0 .41-.18.41-.41 0-.23-.19-.41-.41-.41zM25.4 33.16h-1.62c-.22 0-.41.18-.41.41 0 .23.18.41.41.41h1.62c.22 0 .41-.18.41-.41a.418.418 0 00-.41-.41zM36.21 30.02a.399.399 0 00-.57-.01.41.41 0 00-.01.58l2.46 2.57H27.66c-.22 0-.41.18-.41.41 0 .23.18.41.41.41h10.42l-2.46 2.57a.41.41 0 00.01.58c.08.08.18.11.28.11.11 0 .21-.04.29-.13l3.11-3.26a.41.41 0 000-.58l-3.1-3.25z'
    />
  </svg>
)

export default SvgFeature1
