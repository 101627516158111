import React from 'react'

const SvgTableEn = props => (
  <svg id='table_en_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 500 140' xmlSpace='preserve' {...props}>
    <style>
      {
        '.table_en_svg__st1{fill:none;stroke:#9aa9b2;stroke-miterlimit:10}.table_en_svg__st2{fill:#182940}.table_en_svg__st3{font-family:&apos;SourceHanSansCN-Regular-GBpc-EUC-H&apos;}.table_en_svg__st4{font-size:12px}.table_en_svg__st5{font-family:&apos;OpenSans&apos;}'
      }
    </style>
    <path fill='#d3d9dd' stroke='#9aa9b2' strokeMiterlimit={10} d='M.5 4.75h498.99v26H.5z' />
    <path className='table_en_svg__st1' d='M.5 30.75h498.99v52H.5z' />
    <path className='table_en_svg__st1' d='M85.33 56.75h414.16v26H85.33zM85.33 82.75h414.16v26H85.33z' />
    <path className='table_en_svg__st1' d='M.5 82.75h498.99v52H.5zM85.5 31.25v103M167.5 5.25v130M325.5 5.25v130' />
    <text transform='translate(95.005 47.25)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'rsync'}
    </text>
    <text transform='translate(95.005 73.583)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'Raysync'}
    </text>
    <text transform='translate(95.005 99.917)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'rsync'}
    </text>
    <text transform='translate(95.005 126.25)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'Raysync'}
    </text>
    <text transform='translate(178.005 48.25)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'9198'}
    </text>
    <text transform='translate(178.005 74.583)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'104'}
    </text>
    <text transform='translate(178.005 100.917)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'7767'}
    </text>
    <text transform='translate(178.005 127.25)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'99'}
    </text>
    <text transform='translate(337.005 49.25)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'114'}
    </text>
    <text transform='translate(337.005 75.583)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'9800'}
    </text>
    <text transform='translate(337.005 101.917)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'135'}
    </text>
    <text transform='translate(337.005 128.25)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'10300'}
    </text>
    <text transform='translate(8.944 21.25)' className='table_en_svg__st2 table_en_svg__st5 table_en_svg__st4'>
      {'Test scenarios'}
    </text>
    <text transform='translate(178.004 22.25)' className='table_en_svg__st2 table_en_svg__st5 table_en_svg__st4'>
      {'Completion duration(s)'}
    </text>
    <text transform='translate(337.004 23.25)' className='table_en_svg__st2 table_en_svg__st5 table_en_svg__st4'>
      {'Average speed(KB/s)'}
    </text>
    <text transform='translate(8.944 61.25)' className='table_en_svg__st2 table_en_svg__st5 table_en_svg__st4'>
      {'Upload'}
    </text>
    <text transform='translate(8.944 107.25)' className='table_en_svg__st2 table_en_svg__st5 table_en_svg__st4'>
      {'Dawnload'}
    </text>
  </svg>
)

export default SvgTableEn
